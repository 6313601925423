import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { useParams } from "react-router-dom";
import { message, Row, Col, Typography } from "antd";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import flatpickr from "flatpickr";

const { Title } = Typography;

const AddFollowUpDateTime = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [patientSelectedName, setPatientSelectedName] = useState("");
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  // const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  // const [dateSelectedDetails, setSelectedDateDetails] = useState([]);
  // const [dateSelectedId, setDateSelectedId] = useState("");
  // const [dateStoringValue, setDateStoringValue] = useState("");
  // const [timeSelectedDetails, setSelectedTimeDetails] = useState([]);
  // const [timeSelectedId, setTimeSelectedId] = useState("");
  // const [timeStoringValue, setTimeStoringValue] = useState("");
  const [defaultDepartment, setDefaultDepartment] = useState({});
  const [defaultDoctor, setDefaultDoctor] = useState({});
  const [status, setStatus] = useState("");
  const [time, setTime] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  // fetch appointment details by id
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${apiBaseUrl}/staff/getBookedDoctorAppointment/${appointmentId}`,
        config
      )
      .then((response) => {
        if (response.data.success) {
          setPatientSelectedName(response.data.data.fullname);
          setStatus(response.data.data.status);
          if (
            response.data.data.refer?.toDoctor != null ||
            response.data.data.refer?.toDoctor != undefined
          ) {
            setDefaultDepartment({
              value: response.data.data.refer?.toDepartment._id,
              label: response.data.data.refer?.toDepartment.department,
            });
            // setDepartmentSelectedId(response.data.data.refer?.toDepartment._id);
            setDefaultDoctor({
              value: response.data.data.refer?.toDoctor._id,
              label: response.data.data.refer?.toDoctor.fullname,
            });
            setDoctorSelectedId(response.data.data.refer?.toDoctor._id);
            // setDepartmentSelectedId(response.data.data.);
          } else {
            setDefaultDepartment({
              value: response.data.data.department._id,
              label: response.data.data.department.department,
            });
            // setDepartmentSelectedId(response.data.data.department._id);
            setDefaultDoctor({
              value: response.data.data.doctorId._id,
              label: response.data.data.doctorId.fullname,
            });
            setDoctorSelectedId(response.data.data.doctorId._id);
            // setDepartmentSelectedId
          }
        }
      })
      .catch((error) => {
        console.error("Failed to get appointment detail", error);
        message.error(
          "Failed to get selected appointment detail! Please try again."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // fetch dateTime from appointmet date time
  // useEffect(() => {
  //   if (doctorSelectedId) {
  //     axios
  //       .get(`${apiBaseUrl}/staff/appointment/dateAndtime/${doctorSelectedId}`)
  //       .then((response) => {
  //         if (response.data.success) {
  //           const options = response.data.data.map((date) => ({
  //             value: date.date,
  //             label: date.date,
  //             time: date.time,
  //           }));
  //           setSelectedDateDetails(options);
  //         }
  //       });
  //   }
  // }, [doctorSelectedId]);

  // const handleDateInputChange = (selectedDate) => {
  //   if (selectedDate) {
  //     setDateStoringValue(selectedDate);
  //     setDateSelectedId(selectedDate.value);
  //     setSelectedTimeDetails(selectedDate.time);
  //     setTimeStoringValue("");
  //   }
  // };

  // handle time for selected date:
  // const timeDataOptions = timeSelectedDetails.map((time) => ({
  //   value: time,
  //   label: time,
  // }));

  // const handleTimeInputChange = (selectedTime) => {
  //   if (selectedTime) {
  //     setTimeStoringValue(selectedTime);
  //     setTimeSelectedId(selectedTime.value);
  //   }
  // };

  const addFollowUpDateTime = (e) => {
    setActionLoading(true);
    e.preventDefault();

    const data = {
      status,
      assigningDoctorId: doctorSelectedId,
      followUp_appointmentDate: appointmentDate,
      followUp_appointmentTime: time,
    };

    axios
      .post(
        `${apiBaseUrl}/add/datetime/staff/appointment/followup/${appointmentId}`,
        data,
        config
      )
      .then((response) => {
        if (response.data.success) {
          message.success(
            "DateTime Assigned Successfully",
            0.6,
            function onClose() {
              window.location.replace("/dashboard/view/inprogress/appointment");
            }
          );
        } else {
          message.error("Something went wrong! Please try again.");
        }
      })
      .catch((err) => {
        console.error("Error booking appointment:", err);
        message.error("Error booking appointment! Please try again.");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleTimeChange = (selectedDates) => {
    const selectedTime = selectedDates[0];
    const formattedTime = flatpickr.formatDate(selectedTime, "h:i K");
    setTime(formattedTime);
  };

  return (
    <div>
      {(actionLoading || loading) && <LoadingScreen />}

      <div>
        <form
          className="forbox w-full max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={addFollowUpDateTime}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Add Followup date-time</Title>
          </div>
          <p className="text-base -mb-2 -mt-1">
            Patient Name:{" "}
            <span className="text-gray-700 italic">{patientSelectedName}</span>
          </p>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Department</label>
              <Select
                placeholder="Select department"
                value={defaultDepartment}
                isSearchable
                isDisabled
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <label className="text-base ms-1">Doctor</label>
              <Select
                value={defaultDoctor}
                placeholder="Select doctor"
                isSearchable
                isDisabled
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>

          {/* future usecase */}

          {/* <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Date</label>
              <Select
                options={dateSelectedDetails}
                value={dateStoringValue}
                onChange={handleDateInputChange}
                placeholder="Select date"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Time</label>
              <Select
                options={timeDataOptions}
                value={timeStoringValue}
                onChange={handleTimeInputChange}
                placeholder="Select time"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row> */}

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Date</label>
              <input
                id="appointmentDate"
                type="date"
                onChange={(e) => {
                  setAppointmentDate(e.target.value);
                }}
                min={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                name="appointmentDate"
                placeholder="Select Date"
                required
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Time</label>
              <Flatpickr
                data-enable-time
                data-disable-date
                placeholder="Pick a time"
                onChange={(selectedDates, dateStr, flatpickrInstance) =>
                  handleTimeChange(selectedDates, dateStr, flatpickrInstance)
                }
                className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md transition-colors duration-200"
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "h:i K",
                  time_24hr: false,
                  allowInput: true,
                  clearIcon: true,
                }}
                required
              />
            </Col>
          </Row>

          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default AddFollowUpDateTime;
