import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingScreen from "../lib/constants/loadingScreen";
import { Table, Input, Spin, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { MdDelete, MdEditSquare } from "react-icons/md";
import { FaPrint } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import ReportViewModal from "./report/report_modal";
import { render } from "@testing-library/react";


const ViewLabSelfReports = () => {


    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [details, setDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredDetails, setFilteredDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [buttonLoading, setButtonLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
        },
    };

    useEffect(() => {
        fetchData();
    }, []);



    const fetchData = () => {
        setLoading(true);
        axios
            .get(`${apiBaseUrl}/view/labselfreports`, config)
            .then((response) => {
                console.log(
                    "reports", response.data
                );
                setDetails(response.data);
                setFilteredDetails(response.data);
            })
            .catch((error) => {
                console.error("Error fetching lab reports:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleReportDelete = async (report_id) => {
        try {
            setActionLoading(true);
            if (window.confirm("Are you sure want to delete this report?")) {
                const response = await axios.delete(
                    `${apiBaseUrl}/report/delete/${report_id}`,
                    config
                );
                if (response.data.success) {
                    message.success(response.data.message);
                    fetchData();
                } else {
                    message.error(
                        response?.error?.message || "Failed to delete the report."
                    );
                }
            }
        } catch (e) {
            console.error("Failed to delete report:", e);
            message.error("Failed to delete! Please try again.");
        } finally {
            setActionLoading(false);
        }
    };

    const filterReports = useCallback(
        debounce((searchTerm) => {
            if (searchTerm.trim() !== "") {
                const filtered = details.filter(
                    (report) =>

                        report?.patientId?.userID
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        report?.patientId?.fullname
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||

                        report?.reportName.toLowerCase().includes(searchTerm.toLowerCase())
                );
                setFilteredDetails(filtered);
            } else {
                fetchData();
            }
        }, 500),
        [searchTerm, details]
    );

    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        filterReports(searchTerm);
    };



    const columns = [

        {
            title: "UserID",
            dataIndex: ["patientId", "userID"],
            key: "userID",
        },
        {
            title: "Patient Name",
            dataIndex: ["patientId", "fullname"],
            key: "fullname",
        },
        {
            title: "Phone",
            dataIndex: ["patientId", "phone"],
            key: "phone",
        },
        {
            title: "Address",
            dataIndex: ["patientId", "address"],
            key: "address",
        },
        {
            title: "Report Name",
            dataIndex: "reportName",
            key: "reportName",
        },
        {
            title: "Date added",
            dataIndex: "createdAt",
            key: "date",
            render: (createdAt) => createdAt.split("T")[0],
        },
        {
            title: "Action",
            key: "action",
            width: 200,
            render: (_, record) => (
                // console.log(record?.appointmentId.appointmentHistory[record.appointmentId.appointmentHistory.length - 1].reports),
                // console.log(record?._id),
                <>
                    <div className="flex space-x-2">
                        <button
                            onClick={() => {
                                setSelectedReport(record.report);
                                setIsModalOpen(true);
                            }}
                            className="inline-flex items-center px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700"
                        >
                            <GrView size={20} />
                        </button>
                        {(
                            <Link
                                to={`/dashboard/update-report/${record._id}`}
                                state={{
                                    returnPage: "ViewSelfReports",
                                }}
                                className="text-indigo-600 hover:text-indigo-900"
                            >
                                <button className="inline-flex items-center px-4 py-2 rounded-md bg-gray-600 text-white hover:bg-gray-700">
                                    <MdEditSquare size={20} />
                                </button>
                            </Link>
                        )}
                        {/* {shouldShowButtons(record) && ( */}
                        <Link
                            to={`/dashboard/printout/selfreport/${record._id}`}
                            className="text-indigo-600 hover:text-indigo-900"
                        >
                            <button className="inline-flex items-center px-4 py-2 rounded-md bg-gray-600 text-white hover:bg-gray-700">
                                <FaPrint size={20} />
                            </button>
                        </Link>
                        {/* // )} */}
                        {(
                            <Link
                                onClick={() => {
                                    handleReportDelete(record._id);
                                }}
                                className="text-indigo-600 hover:text-indigo-900"
                            >
                                <button className="inline-flex items-center px-4 py-2 rounded-md bg-red-700 text-white hover:bg-red-800">
                                    <MdDelete size={20} />
                                </button>
                            </Link>
                        )}
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            {actionLoading && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
                    <LoadingScreen />
                </div>
            )}
            <ReportViewModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                report={selectedReport}
            />

            <div className="container max-w-8xl mx-auto">
                <div className="mb-4">
                    <h1 className="text-3xl font-bold decoration-gray-400">
                        All Reports
                    </h1>
                    <div className="my-3 flex justify-between items-center">
                        <Input
                            placeholder="Search..."
                            prefix={<SearchOutlined />}
                            allowClear
                            value={searchTerm}
                            onChange={handleSearch}
                            style={{ width: 300 }}
                        />
                        <Link to="/dashboard/add-self-report">
                            <button className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
                                Add Test Report
                            </button>
                        </Link>
                    </div>

                </div>
                <div className="flex flex-col">
                    <Table
                        className="rounded-md shadow-md"
                        dataSource={filteredDetails}
                        columns={columns}
                        rowKey="_id"
                        pagination={{
                            className: "pe-3",
                            defaultPageSize: 15,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        loading={{
                            indicator: <Spin size="large" />,
                            spinning: loading,
                        }}
                    />
                </div>
            </div>
        </>
    );
};
export default ViewLabSelfReports;
