import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import LoadingScreen from "../lib/constants/loadingScreen";
import { useParams, Link } from "react-router-dom";
import { message, Row, Col, Typography } from "antd";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import flatpickr from "flatpickr";
import { calculateAge } from "../lib/constants/calculateAge";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import "./placeholder.css";

const { Title } = Typography;

const UpdateAppointmentRequestByStaff = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  // const [patientDetails, setPatientDetails] = useState([]);
  const [problem, setProblem] = useState("");
  const [address, setAddress] = useState("");
  const [DOB, setDOBinAD] = useState("");
  const [inputAge, setInputAge] = useState("");
  const [patientSelectedName, setPatientSelectedName] = useState("");
  const [patientSelectedContact, setPatientSelectedContact] = useState("");
  // const [patientSelectedAge, setPatientSelectedAge] = useState("");
  const [departmentSelectedDetails, setDepartmentSelectedDetails] = useState(
    []
  );
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  const [departmentStoringValue, setDepartmentStoringValue] = useState(null);
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [dateSelectedDetails, setSelectedDateDetails] = useState([]);
  const [doctorStoringValue, setDoctorStoringValue] = useState(null);
  // const [dateSelectedId, setDateSelectedId] = useState("");
  // const [dateStoringValue, setDateStoringValue] = useState(null);
  // const [timeSelectedDetails, setSelectedTimeDetails] = useState([]);
  // const [timeSelectedId, setTimeSelectedId] = useState("");
  // const [timeStoringValue, setTimeStoringValue] = useState(null);
  const [defaultDepartment, setDefaultDepartment] = useState({});
  const [defaultDoctor, setDefaultDoctor] = useState({});
  // const [defaultDate, setDefaultDate] = useState({});
  // const [defaultTime, setDefaultTime] = useState({});
  // const [previousSelectedTime, setPreviousSelectedTime] = useState("");
  const [time, setTime] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");

  // age calculate
  const [isDOBMatched, setIsDOBMatched] = useState(false);
  const [dateBS, setDateBS] = useState("");
  const [dateAD, setDateAD] = useState("");
  const [defaultDateBS, setDefaultDateBS] = useState("");
  let calculatedAge;

  useEffect(() => {
    calculatedAge = calculateAge(DOB);

    if (
      calculatedAge !== undefined &&
      calculatedAge !== "Invalid date of birth"
    ) {
      setInputAge(calculatedAge);

      if (dateAD === DOB) {
        setIsDOBMatched(true);
      } else {
        setIsDOBMatched(false);
      }
    } else {
      setInputAge(null);
    }
  }, [DOB, dateAD]);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  // fetch appointment details by id
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${apiBaseUrl}/staff/getBookedDoctorAppointment/${appointmentId}`,
        config
      )
      .then((response) => {
        if (response.data.success) {
          // setInputAge(response.data.data.age);
          setPatientSelectedName(response.data.data.fullname);
          setPatientSelectedContact(response.data.data.mobile);
          // setPatientSelectedAge(response.data.data.age);

          setDOBinAD(response.data?.data?.DOB?.split("T")[0]);
          setAddress(response.data.data?.address);
          setProblem(response.data.data.problem);
          setDefaultDepartment({
            value: response.data.data.department._id,
            label: response.data.data.department.department,
          });
          setDepartmentSelectedId(response.data.data.department._id);
          setDefaultDoctor({
            value: response.data.data.doctorId._id,
            label: response.data.data.doctorId.fullname,
          });
          setDoctorSelectedId(response.data.data.doctorId._id);
          // setDefaultDate({
          //   value: response.data.data.date,
          //   label: response.data.data.date,
          // });
          // setDateSelectedId(response.data.data.date);
          // setDefaultTime({
          //   value: response.data.data.time,
          //   label: response.data.data.time,
          // });
          // setTimeSelectedId(response.data.data.time);
          // setPreviousSelectedTime(response.data.data.time);
          setAppointmentDate(response.data.data.date);

          // if (patientSelectedDOB !== null || patientSelectedDOB !== undefined) {
          //   const patientAge = calculateAge(response.data?.data?.DOB);
          //   setPatientSelectedAge(patientAge);
          // }
        }
      })
      .catch((error) => {
        console.error("Failed to get appointment detail", error);
        message.error(
          "Failed to get selected appointment detail! Please try again."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // fetching patient details
  // useEffect(() => {
  //   axios
  //     .get(`${apiBaseUrl}/viewpatientsstaff`, config)
  //     .then((response) => {
  //       if (response.data.success) {
  //         const options = response.data.data.map((patient) => ({
  //           value: patient._id,
  //           label: patient.fullname,
  //           age: patient.age,
  //           contact: patient.phone,
  //           DOB: patient.DOB,
  //           address: patient.address,
  //           DOBinBS: patient.DOBinBS,
  //         }));
  //         setPatientDetails(options);
  //       }
  //     })
  //     .catch((e) => {
  //       console.error("Failed to get user details:", e);
  //       message.error("Failed to get user details! Please try again.");
  //     });
  // }, []);

  // fetching department section
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((department) => ({
            value: department._id,
            label: department.department,
          }));
          setDepartmentSelectedDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch department details:", e);
        message.error("Failed to get department details! Please try again.");
      });
  }, []);

  const handleDepartmentInputChange = (selectedDepartment) => {
    if (selectedDepartment) {
      setDepartmentStoringValue(selectedDepartment);
      setDepartmentSelectedId(selectedDepartment.value);
      setDoctorStoringValue("");
      // setDateStoringValue("");
      // setTimeStoringValue("");
    }
  };

  // Fetching doctor details based on selected department:
  useEffect(() => {
    if (departmentSelectedId) {
      axios
        .get(`${apiBaseUrl}/doctor/category/${departmentSelectedId}`)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        });
    }
  }, [departmentSelectedId]);

  const handleDoctorInputChange = (selectedDoctor) => {
    if (selectedDoctor) {
      setDoctorStoringValue(selectedDoctor);
      setDoctorSelectedId(selectedDoctor.value);
      // setDateStoringValue("");
      // setTimeStoringValue("");
    }
  };

  // fetch dateTime from appointmet date time
  // useEffect(() => {
  //   if (doctorSelectedId) {
  //     axios
  //       .get(`${apiBaseUrl}/staff/appointment/dateAndtime/${doctorSelectedId}`)
  //       .then((response) => {
  //         if (response.data.success) {
  //           console.log(response.data.data);
  //           const options = response.data.data.map((date) => ({
  //             value: date.date,
  //             label: date.date,
  //             time: date.time,
  //           }));
  //           setSelectedDateDetails(options);
  //         }
  //       });
  //   }
  // }, [doctorSelectedId]);

  // const handleDateInputChange = (selectedDate) => {
  //   if (selectedDate) {
  //     setDateStoringValue(selectedDate);
  //     setDateSelectedId(selectedDate.value);
  //     setTimeStoringValue("");
  //   }
  // };

  // for reloading the data when date is selected
  // useEffect(() => {
  //   if (dateSelectedId) {
  //     const selectedDate = dateSelectedDetails.find(
  //       (date) => date.value === dateSelectedId
  //     );
  //     if (selectedDate && selectedDate.value === defaultDate.value) {
  //       const updatedTimeOptions = [
  //         ...(selectedDate.time || []).map((time) => ({
  //           value: time,
  //           label: time,
  //         })),
  //         { value: previousSelectedTime, label: previousSelectedTime },
  //       ];
  //       setSelectedTimeDetails(updatedTimeOptions);
  //     } else if (selectedDate) {
  //       setSelectedTimeDetails(
  //         selectedDate.time.map((time) => ({ value: time, label: time }))
  //       );
  //     }
  //   }
  // }, [dateSelectedId, dateSelectedDetails, defaultDate, previousSelectedTime]);

  // const handleTimeInputChange = (selectedTime) => {
  //   if (selectedTime) {
  //     setTimeSelectedId(selectedTime.value);
  //     setTimeStoringValue(selectedTime);
  //     console.log(selectedTime);
  //   }
  // };

  const updateAppointment = (e) => {
    setActionLoading(true);
    e.preventDefault();

    const data = {
      fullname: patientSelectedName,
      mobile: patientSelectedContact,
      address,
      // age: inputAge,
      DOB,
      DOBinBS: dateBS,
      problem,
      department: departmentSelectedId,
      doctorId: doctorSelectedId,
      date: appointmentDate,
      time,
    };

    axios
      .patch(
        `${apiBaseUrl}/update/date-time/patient-appointment/${appointmentId}`,
        data,
        config
      )
      .then((response) => {
        if (response.data.success) {
          message.success(
            "Appointment Updated Successfully",
            0.6,
            function onClose() {
              window.location.replace("/dashboard/pending/user/appointments");
            }
          );
        } else {
          message.error("Something went wrong! Please try again.");
        }
      })
      .catch((err) => {
        console.error("Error updating appointment:", err);
        message.error("Error updating appointment! Please try again.");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleTimeChange = (selectedDates) => {
    const selectedTime = selectedDates[0];
    const formattedTime = flatpickr.formatDate(selectedTime, "h:i K");
    setTime(formattedTime);
  };

  const dateBStoAD = () => {
    if (dateAD) {
      setDOBinAD(dateAD);
    } else {
      setInputAge(null);
    }
  };

  const handleDateInBS = ({ bsDate, adDate }) => {
    setDateBS(bsDate);
    setDateAD(adDate);
  };

  return (
    <div>
      {(actionLoading || loading) && <LoadingScreen />}

      <div>
        <form
          className="forbox w-full max-w-2xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={updateAppointment}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Update appointment</Title>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Patient Name</label>
              <input
                onChange={(e) => {
                  setPatientSelectedName(e.target.value);
                }}
                value={patientSelectedName}
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-lg text-gray-700"
                name="title"
                placeholder="Fullname"
                required
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div>
                <label className="text-base">Contact</label>
                <input
                  value={patientSelectedContact}
                  onChange={(e) => {
                    setPatientSelectedContact(e.target.value);
                  }}
                  className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                  name="title"
                  placeholder="Contact"
                  required
                />
              </div>
            </Col>
            <Col span={10}>
              <div>
                <label className="text-base">DOB (in BS)</label>
                <Calendar
                  key={defaultDateBS}
                  onChange={handleDateInBS}
                  theme="blue"
                  defaultDate={defaultDateBS}
                  hideDefaultValue={defaultDateBS ? false : true}
                  language="en"
                  placeholder="Select Date(BS)"
                  className="border border-gray-300 border py-1.5 px-3 rounded-md text-gray-800 custom-placeholder w-full"
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div>
                <label className="text-base">Address</label>
                <input
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                  name="title"
                  placeholder="Address"
                />
              </div>
            </Col>
            <Col span={7}>
              <div>
                <label className="text-base ms-1">DOB (in AD)</label>
                <input
                  id="DOB"
                  value={DOB}
                  type="date"
                  onChange={(e) => {
                    setDOBinAD(e.target.value);
                  }}
                  max={new Date().toISOString().split("T")[0]}
                  className="border-solid border-gray-300 border h-10 py-2 px-2 w-full rounded-md text-gray-700 date-picker"
                  name="dob"
                  placeholder="Select Date"
                />
              </div>
            </Col>
            <Col span={5}>
              <div>
                <label className="text-base ms-1">Age</label>
                <input
                  onChange={(e) => {
                    setInputAge(e.target.value);
                  }}
                  value={inputAge}
                  className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md"
                  name="age"
                  type="text"
                  placeholder="Age"
                  readOnly
                  required
                />
              </div>
            </Col>
          </Row>
          {!isDOBMatched && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="-mt-3 -mb-4 flex justify-end">
                  <p className="text-red-500 italic me-2">
                    The selected DOB(in BS) does not match the DOB(in AD).
                  </p>
                  <Link
                    onClick={dateBStoAD}
                    className="text-blue-500 italic border-b hover:text-blue-600 font-semibold inline-flex items-center"
                  >
                    Click here to correct it.
                  </Link>
                </div>
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Problem</label>
              <input
                onChange={(e) => {
                  setProblem(e.target.value);
                }}
                className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                name="title"
                placeholder="Problem"
                value={problem}
                required
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Department</label>
              <Select
                options={departmentSelectedDetails}
                placeholder="Select department"
                value={
                  departmentStoringValue !== null
                    ? departmentStoringValue
                    : defaultDepartment
                }
                onChange={handleDepartmentInputChange}
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <label className="text-base ms-1">Doctor</label>
              <Select
                options={doctorSelectedDetails}
                value={
                  doctorStoringValue !== null
                    ? doctorStoringValue
                    : defaultDoctor
                }
                onChange={handleDoctorInputChange}
                placeholder="Select doctor"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Date</label>
              {/* <Select
                options={dateSelectedDetails}
                value={
                  dateStoringValue !== null ? dateStoringValue : defaultDate
                }
                onChange={handleDateInputChange}
                placeholder="Select date"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              /> */}
              <input
                id="appointmentDate"
                type="date"
                value={appointmentDate}
                onChange={(e) => {
                  setAppointmentDate(e.target.value);
                }}
                min={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                name="appointmentDate"
                placeholder="Select Date"
              />
            </Col>
            <Col span={12}>
              <label className="text-base ms-1">Time</label>
              {/* <Select
                options={timeSelectedDetails}
                value={
                  timeStoringValue !== null ? timeStoringValue : defaultTime
                }
                onChange={handleTimeInputChange}
                placeholder="Select time"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              /> */}

              <Flatpickr
                data-enable-time
                data-disable-date
                placeholder="Pick a time"
                onChange={(selectedDates, dateStr, flatpickrInstance) =>
                  handleTimeChange(selectedDates, dateStr, flatpickrInstance)
                }
                className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md transition-colors duration-200"
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "h:i K",
                  time_24hr: false,
                  allowInput: true,
                  clearIcon: true,
                }}
                required
              />
            </Col>
          </Row>
          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default UpdateAppointmentRequestByStaff;
