import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { appointmentContext } from "../../pages/ViewStaffReferredAppointments";
import { message } from "antd";

const ReferredStaffActionAppointment = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const {
    appointmentId,
    appointmentNumber,
    full_name,
    problem,
    report,
    referDoctorAvailable,
    departmentCheckIfInReferred,
    doctorCheckIfInReferred,
    appointmentHistory,
    departmentIsSelectedCheckIfInReferred,
  } = useContext(appointmentContext);
  let appointment_status;
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  const updateAppointmentStatus = () => {
    appointment_status = "Ended";
    if (
      window.confirm("Are you sure want to change this appointment status?")
    ) {
      setActionLoading(true);
      const data = {
        appointmentStatus: appointment_status,
      };

      axios
        .put(
          `${apiBaseUrl}/staff/updateAppointmentStatus/` + appointmentId,
          data,
          config
        )
        .then((response) => {
          if (response.data.msg == "Updated Status") {
            message.success("Appointment ended", 0.6, function onClose() {
              window.location.reload();
            });
          } else {
            message.error("Failed To Update");
          }
        })
        .catch((err) => {
          console.error("Error updating appointment status:", err);
          message.error(
            "Failed to update appointment status! Please try again."
          );
        })
        .finally(() => {
          setActionLoading(false);
        });
    }
  };

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <div className="bg-white px-4 pt-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:text-left">
            <h1
              className="text-3xl leading-6 font-medium text-center text-gray-900"
              id="modal-title"
            >
              Actions
            </h1>
            <div className="">
              <p className="text-sm text-gray-500 mx-5">
                <div className="py-2 mx-5">
                  <ul className="my-4 space-y-3 mx-8">
                    {referDoctorAvailable != null ? (
                      <>
                        <li>
                          <Link
                            to={`/dashboard/viewreport/${appointmentId}`}
                            state={{ report, appointmentNumber, full_name }}
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              View Report
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/staff/refer_department/${appointmentId}`}
                            state={{
                              full_name,
                              problem,
                              departmentCheckIfInReferred,
                              doctorCheckIfInReferred,
                            }}
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              Refer Department
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/diagnosis/${appointmentId}`}
                            state={{ full_name, problem, appointmentNumber, appointmentHistory }}
                            className="flex items-center px-12 py-2.5 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              Diagnosis
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/viewOngoingAppointmentbyDoctor/add_prescription/${appointmentId}`}
                            state={{ full_name, problem }}
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              Prescription
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/advice/${appointmentId}`}
                            state={{ full_name, problem, appointmentNumber, appointmentHistory }}
                            className="flex items-center px-12 py-2.5 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              Advices
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/summary/${appointmentId}`}
                            state={{ full_name, problem, appointmentNumber, appointmentHistory }}
                            className="flex items-center px-12 py-2.5 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              Summary
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={updateAppointmentStatus}
                            className="flex items-center px-12 py-4 text-center font-bold text-red-600 shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              End Appointment
                            </span>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link
                            to={`/dashboard/viewreport/${appointmentId}`}
                            state={{ report, appointmentNumber, full_name }}
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              View Report
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/viewAppointmentbyDoctor/view/prescription/${appointmentId}`}
                            state={{ full_name, problem }}
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              View Prescription
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/onlyViewDiagnosis/${appointmentId}`}
                            state={{ full_name, problem, appointmentNumber, appointmentHistory }}
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              View Diagnosis
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/onlyViewAdvice/${appointmentId}`}
                            state={{ full_name, problem, appointmentNumber, appointmentHistory }}
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              View Advices
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/onlyViewSummary/${appointmentId}`}
                            state={{ full_name, problem, appointmentNumber, appointmentHistory }}
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              View Summary
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/staff/change/refer_department/${appointmentId}`}
                            state={{
                              full_name,
                              problem,
                              departmentCheckIfInReferred,
                              doctorCheckIfInReferred,
                              departmentIsSelectedCheckIfInReferred,
                            }}
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              Change Department
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={updateAppointmentStatus}
                            className="flex items-center px-12 py-4 text-center font-bold text-red-600 shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span className="flex-1 whitespace-nowrap">
                              End Appointment
                            </span>
                          </Link>
                        </li>

                        <div className="text-center">
                          <p className="text-center">
                            To assign a doctor, Redirect to:
                          </p>
                          <Link
                            to={"/dashboard/refer"}
                            className="text-sky-500 underline"
                          >
                            Refer Section
                          </Link>
                        </div>
                      </>
                    )}
                  </ul>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferredStaffActionAppointment;
