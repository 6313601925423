import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function BuyerProfilePieChart() {
  const navigate = useNavigate();
  const [button1Text, setButton1Text] = useState("");
  const [button2Text, setButton2Text] = useState("");
  const [button3Text, setButton3Text] = useState("");
  const [button4Text, setButton4Text] = useState("");
  const [button1Url, setButton1Url] = useState("");
  const [button2Url, setButton2Url] = useState("");
  const [button3Url, setButton3Url] = useState("");
  const [button4Url, setButton4Url] = useState("");

  const isDoctor = localStorage.getItem("doctorticket");
  const isStaff = localStorage.getItem("staffticket");
  const isLabReporter = localStorage.getItem("labreporterticket");
  const isPharma = localStorage.getItem("pharmaticket");
  const isAdmin = localStorage.getItem("adminticket");

  useEffect(() => {
    if (isDoctor) {
      setButton1Text("Ongoing Appointments");
      setButton2Text("Referred Appointments");
      setButton3Text("FollowUp Appointments");
      setButton4Text("Completed Appointments");
      setButton1Url(`/dashboard/viewOngoingAppointmentbyDoctor`);
      setButton2Url(`/dashboard/viewReferredAppointment/toDoctor`);
      setButton3Url(`/dashboard/view/followup/appointments`);
      setButton4Url(`/dashboard/viewEndedAppointmentbyDoctor`);
      const buttons = document.querySelectorAll(".button-style");
      buttons.forEach((button) => {
        button.style.width = `240px`;
        button.style.marginTop = `1px`;
      });
    } else if (isStaff) {
      setButton1Text("Create User");
      setButton2Text("Check Refers");
      setButton3Text("Appt.. Requests");
      setButton4Text("Create Appointment");
      setButton1Url(`/dashboard/patientadd`);
      setButton2Url(`/dashboard/refer`);
      setButton3Url(`/dashboard/pending/user/appointments`);
      setButton4Url(`/dashboard/add/new/appointment`);
      const buttons = document.querySelectorAll(".button-style");
      buttons.forEach((button) => {
        button.style.width = `200px`;
        button.style.marginTop = `1px`;
      });
    } else if (isLabReporter) {
      setButton1Text("All Reports");
      setButton2Text("Appointments");
      setButton1Url(`/dashboard/view/labreports`);
      setButton2Url(`/dashboard/viewappointment`);
      const buttons = document.querySelectorAll(".button-style");
      buttons.forEach((button) => {
        button.style.width = `200px`;
        button.style.marginTop = `1px`;
      });
    } else if (isPharma) {
      setButton1Text("Medicines");
      setButton2Text("Billing Archives");
      setButton1Url(`/dashboard/medicine`);
      setButton2Url(`/dashboard/bills`);
      const buttons = document.querySelectorAll(".button-style");
      buttons.forEach((button) => {
        button.style.width = `200px`;
        button.style.marginTop = `1px`;
      });
    }
  }, []);

  const handleButton1Click = () => {
    navigate(button1Url);
  };
  const handleButton2Click = () => {
    navigate(button2Url);
  };
  const handleButton3Click = () => {
    navigate(button3Url);
  };
  const handleButton4Click = () => {
    navigate(button4Url);
  };

  return (
    <div className="w-[20rem] h-[22rem] bg-white p-4 rounded-md border border-gray-200 flex flex-col">
      <strong className="text-gray-700 font-medium">Quick Access Links</strong>
      <div className="mt-3 w-full flex-1 text-xs">
        <button
          type="button"
          onClick={handleButton1Click}
          className="button-style relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white focus:ring-2 focus:outline-none focus:ring-cyan-200"
        >
          <span className="relative flex justify-center w-full items-center px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
            {button1Text}
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </span>
        </button>
        <button
          type="button"
          onClick={handleButton2Click}
          className="button-style relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white focus:ring-2 focus:outline-none focus:ring-cyan-200"
        >
          <span className="relative flex justify-center w-full items-center px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
            {button2Text}
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </span>
        </button>
        {(isDoctor || isStaff) && (
          <>
            <button
              type="button"
              onClick={handleButton3Click}
              className="button-style relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white focus:ring-2 focus:outline-none focus:ring-cyan-200"
            >
              <span className="relative flex justify-center w-full items-center px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                {button3Text}
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </span>
            </button>
            <button
              type="button"
              onClick={handleButton4Click}
              className="button-style relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white focus:ring-2 focus:outline-none focus:ring-cyan-200"
            >
              <span className="relative flex justify-center w-full items-center px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                {button4Text}
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </span>
            </button>
          </>
        )}
      </div>
    </div>
  );
}
