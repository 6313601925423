import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Form, Input, Row, Col, message, Button, Modal, Select, Tag } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";

const { TextArea } = Input;




const ViewDiagnosis = () => {
  const diseaseOptions = {
    HEMATOLOGY: "Complete Blood Count (CBC) reveals:\n- Hemoglobin levels\n- White blood cell count\n- Platelet count\n- Red blood cell indices",
    BIOCHEMISTRY: "Basic Metabolic Panel includes:\n- Blood glucose levels\n- Electrolyte balance\n- Kidney function tests\n- Liver enzyme analysis",
    SEROLOGY: "Serological Testing includes:\n- Antibody detection\n- Antigen testing\n- Immunological markers\n- Inflammatory indicators",
    PARASITOLOGY: "Parasitological Examination includes:\n- Microscopic analysis\n- Stool examination\n- Blood smear analysis\n- Culture results"
  };
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();
  // const navigate = useNavigate();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const location = useLocation();
  const { full_name, problem, appointmentNumber, appointmentHistory } = location.state || {};
  const [fullNameData, setFullNameData] = useState("");
  const [problemData, setProblemData] = useState("");
  const [appointmentNumberData, setAppointmentNumberData] = useState("");
  const [hasEditableDiagnosis, setHasEditableDiagnosis] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [currentDiagnosisId, setCurrentDiagnosisId] = useState(null);

  // for add diagnosis modal
  const [selectedDiseases, setSelectedDiseases] = useState([]);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };

  const handleDiseaseSelect = (category) => {
    if (!selectedDiseases.includes(category)) {
      const newDiseases = [...selectedDiseases, category];
      setSelectedDiseases(newDiseases);

      // Update textarea with selected disease text
      const currentText = form.getFieldValue('diagnosis') || '';
      const diseaseText = diseaseOptions[category];
      const newText = currentText ? `${currentText}\n\n${category}:\n${diseaseText}` : `${category}:\n${diseaseText}`;
      form.setFieldsValue({ diagnosis: newText });
    }
  };


  const handleDiseaseRemove = (categoryToRemove) => {
    const newDiseases = selectedDiseases.filter(category => category !== categoryToRemove);
    setSelectedDiseases(newDiseases);

    // Update textarea by removing the category and its text
    const currentText = form.getFieldValue('diagnosis') || '';
    const textParts = currentText.split('\n\n');
    const newText = textParts
      .filter(part => !part.startsWith(`${categoryToRemove}:`))
      .join('\n\n');
    form.setFieldsValue({ diagnosis: newText });
  };


  const handleDiseaseSelectUpdate = (category) => {
    if (!selectedDiseases.includes(category)) {
      const newDiseases = [...selectedDiseases, category];
      setSelectedDiseases(newDiseases);

      // Update textarea with selected disease text
      const currentText = updateForm.getFieldValue('diagnosis') || '';
      const diseaseText = diseaseOptions[category];
      const newText = currentText ? `${currentText}\n\n${category}:\n${diseaseText}` : `${category}:\n${diseaseText}`;
      updateForm.setFieldsValue({ diagnosis: newText });
    }
  };


  const handleDiseaseRemoveUpdate = (categoryToRemove) => {
    const newDiseases = selectedDiseases.filter(category => category !== categoryToRemove);
    setSelectedDiseases(newDiseases);

    // Update textarea by removing the category and its text
    const currentText = updateForm.getFieldValue('diagnosis') || '';
    const textParts = currentText.split('\n\n');
    const newText = textParts
      .filter(part => !part.startsWith(`${categoryToRemove}:`))
      .join('\n\n');
    updateForm.setFieldsValue({ diagnosis: newText });
  };


  // const doctorConfig = {
  //   headers: {
  //     Authorization: "Bearer " + localStorage.getItem("doctorticket"),
  //   },
  // };

  // const staffConfig = {
  //   headers: {
  //     Authorization: "Bearer " + localStorage.getItem("staffticket"),
  //   },
  // };

  useEffect(() => {
    setFullNameData(full_name);
    setProblemData(problem);
    setAppointmentNumberData(appointmentNumber);
    fetchData();


  }, []);

  useEffect(() => {
    const checkEditableDiagnosis = () => {
      if (!details || details.length === 0) return false;

      return details.some(item => {
        if (!item.appointmentId?.appointmentHistory) return false;

        const lastHistory = item.appointmentId.appointmentHistory[
          item.appointmentId.appointmentHistory.length - 1
        ];

        return lastHistory?.diagnosis?.some(
          prescriptionItem => prescriptionItem._id === item._id
        );
      });
    };

    setHasEditableDiagnosis(checkEditableDiagnosis());
  }, [details]);

  const fetchData = () => {
    axios
      .get(`${apiBaseUrl}/diagnosis/${appointmentId}`)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          console.log("Diagnosis Data", response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching diagnosis:", error);
      });
  };

  const showModal = () => {

    setIsModalOpen(true);
  };

  const showUpdateModal = (diagnosis, diagnosisId) => {
    updateForm.setFieldsValue({
      diagnosis: diagnosis,
    });
    setCurrentDiagnosisId(diagnosisId);
    setIsUpdateModalOpen(true);
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        addDiagnosis(values);
        form.resetFields();
        setIsModalOpen(false);
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  const handleUpdateOk = () => {
    // the value of arg is Diagnosis ID

    updateForm
      .validateFields()
      .then((values) => {
        // console.log("Updated Diagnosis:", { currentDiagnosisId, ...values });
        updateDiagnosis(values);
        updateForm.resetFields();
        setIsUpdateModalOpen(false);
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  const handleCancel = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        title: "Are you sure you want to discard changes?",
        content: "Your changes will not be saved.",
        okText: "Yes, discard",
        cancelText: "No, keep editing",
        onOk: () => {
          form.resetFields();
          setSelectedDiseases([]);
          setIsModalOpen(false);
        },
      });
    } else {
      setIsModalOpen(false);
    }
  };



  const handleUpdateCancel = () => {
    if (updateForm.isFieldsTouched()) {
      Modal.confirm({
        title: "Are you sure you want to discard changes?",
        content: "Your changes will not be saved.",
        okText: "Yes, discard",
        cancelText: "No, keep editing",
        onOk: () => {
          updateForm.resetFields();
          setIsUpdateModalOpen(false);
          setSelectedDiseases([]);
        },
      });
    } else {
      setIsUpdateModalOpen(false);
    }
  };

  const updateDiagnosis = async (values) => {
    setActionLoading(true);
    // console.log("Logging in update diagnosis ", values, id);
    const data = {
      diagnosis: values.diagnosis,
    };
    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.put(
          `${apiBaseUrl}/doctor/diagnosis/${currentDiagnosisId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.put(
          `${apiBaseUrl}/staff/diagnosis/${currentDiagnosisId}`,
          data,
          config
        );
      }


      if (response.data.success) {
        message.success(response.data.message, 0.6, function onClose() {
          window.location.reload();
          // fetchData();
        });
      } else {
        message.error(
          response?.data?.error?.error || "Failed to update diagnosis."
        );
      }
    } catch (error) {
      console.error("Failed to update diagnosis:", error);
      message.error("Failed to update diagnosis! Please try again.");
    } finally {
      setActionLoading(false);
    }
  }

  const addDiagnosis = async (values) => {
    setActionLoading(true);

    const data = {
      diagnosis: values.diagnosis,
    };

    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.post(
          `${apiBaseUrl}/doctor/diagnosis/${appointmentId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.post(
          `${apiBaseUrl}/staff/diagnosis/${appointmentId}`,
          data,
          config
        );
      }




      if (response.data.success) {

        message.success(response.data.message, 0.6, function onClose() {
          // window.location.reload();
          fetchData();
        });
      } else {

        message.error(
          response?.data?.error?.error || "Failed to add diagnosis."
        );
      }
    } catch (error) {
      console.error("Failed to add diagnosis:", error);
      message.error("Failed to add diagnosis! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const deleteDiagnosis = async (diagnosis_id) => {
    try {
      setActionLoading(true);
      if (window.confirm("Are you sure want to delete this diagnosis?")) {
        let response;
        if (localStorage.getItem("doctorticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/doctor/diagnosis/${diagnosis_id}`,
            config
          );
        } else if (localStorage.getItem("staffticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/staff/diagnosis/${diagnosis_id}`,
            config
          );
        }
        if (response.data.success) {
          message.success(response.data.message
            // , 0.6, function onClose() {
            // window.location.reload();
            // }
          );
          fetchData();
        } else {
          message.error(
            response?.error?.message || "Failed to delete the diagnosis."
          );
        }
      }
    } catch (e) {
      console.error("Failed to delete diagnosis:", e);
      message.error("Failed to delete! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div className="mb-3">
        <h1 className="text-3xl font-bold decoration-gray-400">Diagnosis</h1>
      </div>

      <div className="flex items-start">
        <div className="max-w-3xl p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow">
          {loading && <LoadingScreen />}

          {!loading && (
            <>
              <div className="text-center">
                <h5 className=" text-xl font-bold tracking-tight text-gray-900">
                  Appointment Number: {" "}
                  <span className="font-semibold italic">{` ${appointmentNumberData}`}</span>
                </h5>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                  Patient Name:{" "}
                  <span className="font-semibold italic">{` ${fullNameData}`}</span>
                </h5>

                <p className="mb-1 font-semibold text-gray-700">
                  Problem: <span className="font-normal">{` ${problemData}`}</span>
                </p>
              </div>
              <hr class="w-48 h-1 mx-auto bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700"></hr>

              {details.length > 0 ? (
                details.map((item) => {
                  return (
                    <div
                      key={item._id}
                      className="relative mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow flex"
                    >
                      {/* Left Content */}
                      <div className="flex-grow">
                        <p
                          className="mb-1 font-semibold text-gray-700"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          Diagnosis Report:{" "}
                          <span className="font-normal">{item.diagnosis}</span>
                        </p>
                        <p className="mb-1 font-semibold text-gray-700">
                          Added by:{" "}
                          <span className="font-normal">
                            Dr. {item.doctorId.fullname}
                          </span>
                        </p>
                      </div>

                      {/* Dotted Vertical Line */}
                      <div className="relative">
                        <div className="absolute top-0 bottom-0 border-r-2 border-dotted border-gray-400"></div>
                      </div>

                      {/* Buttons Section */}

                      {item.appointmentId.appointmentHistory[item.appointmentId.appointmentHistory.length - 1]?.diagnosis.some(diagnosisItem =>
                        diagnosisItem._id === item._id
                      ) && (<div className="flex flex-col items-start pl-6">
                        {/* <Link */}
                        {/* // to={`/dashboard/viewOngoingAppointmentbyDoctor/update_prescription/${item._id}`}
                        // state={{ appointmentId: appointmentId }} */}
                        {/* > */}
                        <div className="ms-2">
                          <button className="px-4 py-1 mb-2 rounded-md bg-gray-600 text-sky-100 hover:bg-gray-700" onClick={() => showUpdateModal(item.diagnosis, item._id)}>
                            Update
                          </button>
                          <Modal
                            title="Update Diagnosis"
                            open={isUpdateModalOpen}
                            onOk={handleUpdateOk}
                            onCancel={handleUpdateCancel}
                          >
                            <Form form={updateForm} onFinish={updateDiagnosis} layout="vertical">
                              <Row gutter={[16, 16]}>
                                <Col span={24}>
                                  <Form.Item
                                    label="Test Type"
                                    name="testType"
                                  >
                                    <Select
                                      placeholder="Select test type"
                                      onChange={handleDiseaseSelectUpdate}
                                      className="w-full"
                                    >
                                      {Object.keys(diseaseOptions).map(category => (
                                        <Select.Option key={category} value={category}>
                                          {category}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <div className="mb-4 flex flex-wrap gap-2">
                                    {selectedDiseases.map(category => (
                                      <Tag
                                        key={category}
                                        closable
                                        onClose={() => handleDiseaseRemoveUpdate(category)}
                                        className="px-2 py-1"
                                      >
                                        {category}
                                      </Tag>
                                    ))}
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    label="Diagnosis"
                                    name="diagnosis"
                                    rules={[
                                      { required: true, message: "Please enter diagnosis." },
                                    ]}
                                  >
                                    <TextArea rows={13} placeholder="Enter diagnosis..." />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </Modal>
                        </div>
                        {/* </Link> */}
                        <Link
                          onClick={() => {
                            deleteDiagnosis(item._id);
                          }}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <div className="ms-2">
                            <button className="px-5 py-1 rounded-md bg-red-700 text-sky-100 hover:bg-red-800">
                              Delete
                            </button>
                          </div>
                        </Link>
                      </div>)}
                    </div>
                  );
                })
              ) : (
                <>
                  <p className="mt-3 text-center">
                    <h5 className="mb-1 text-xl text-red-500 font-semibold tracking-tight text-gray-900">
                      No Diagnosis added!!
                    </h5>
                  </p>
                </>
              )}
            </>
          )}
        </div>
        {!hasEditableDiagnosis && (<div className="ms-2">
          <Button type="primary" onClick={showModal}>
            Add Diagnosis
          </Button>
          <Modal
            title="Add Diagnosis"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form form={form} onFinish={addDiagnosis} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item
                    label="Test Type"
                    name="testType"
                  >
                    <Select
                      placeholder="Select test type"
                      onChange={handleDiseaseSelect}
                      className="w-full"
                    >
                      {Object.keys(diseaseOptions).map(category => (
                        <Select.Option key={category} value={category}>
                          {category}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div className="mb-4 flex flex-wrap gap-2">
                    {selectedDiseases.map(category => (
                      <Tag
                        key={category}
                        closable
                        onClose={() => handleDiseaseRemove(category)}
                        className="px-2 py-1"
                      >
                        {category}
                      </Tag>
                    ))}
                  </div>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Diagnosis"
                    name="diagnosis"
                    rules={[
                      { required: true, message: "Please enter diagnosis." },
                    ]}
                  >
                    <TextArea rows={13} placeholder="Enter diagnosis..." />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>)}
      </div>
    </>
  );
};
export default ViewDiagnosis;
