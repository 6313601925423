import React, { useState, useEffect, createContext, useCallback } from "react";
import axios from "axios";
import { getOrderStatus } from "../lib/helpers";
import ActionFollowUpAppointment from "../components/doctor_modal/endedAndfollowup/actionButtonFollowup";
import { Table, Space, Input, Spin, message, DatePicker, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";

export const appointmentContext = createContext();
const { RangePicker } = DatePicker;

const ViewFollowUpAppointments = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  let contextValue;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [appointmentId, setAppointmentId] = useState("");
  const [appointmentNumber, setAppointmentNumber] = useState("");
  const [appointmentHistory, setAppointmentHistory] = useState([]);
  const [department, setDepartment] = useState("");
  const [full_name, setFullName] = useState("");
  const [problem, setProblem] = useState("");
  const [report, setReport] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("doctorticket"),
    },
  };

  useEffect(() => {
    fetchFollowUpAppointments();
  }, []);

  const fetchFollowUpAppointments = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/doctor/getAppointment/FollowUp`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          setFilteredDetails(response.data.data);
        }
      })
      .catch((e) => {
        console.error("Failed to get follow_up appointments", e);
        message.error("Failed to get follow_up appointments");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleModal = (appointment) => {
    if (appointment && appointment._id) {
      setSelectedAppointment(appointment);
      setIsModalOpen(!isModalOpen);

      setAppointmentId(appointment._id);
      setAppointmentNumber(appointment.appointmentNumber);
      setAppointmentHistory(appointment.appointmentHistory);
      setDepartment(appointment.department);
      setFullName(appointment.fullname);
      setProblem(appointment.problem);
      setReport(appointment.reports);
    }
    setSelectedAppointment(appointment);
    setIsModalOpen(!isModalOpen);
  };
  contextValue = {
    appointmentId,
    appointmentNumber,
    department,
    full_name,
    problem,
    report,
    appointmentHistory,
  };

  const filterAppointments = useCallback(
    debounce((searchTerm, dateRange) => {
      let filtered = details;
      if (searchTerm.trim() !== "") {
        filtered = details.filter(
          (appointment) =>
            appointment?.appointmentNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            appointment?.patientId?.userID
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            appointment?.fullname
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            appointment?.mobile.toLowerCase().includes(searchTerm.toLowerCase())
          //    ||
          // appointment?.followUp_appointmentDate
          //   .toLowerCase()
          //   .includes(searchTerm.toLowerCase()) ||
          // appointment?.followUp_appointmentTime
          //   .toLowerCase()
          //   .includes(searchTerm.toLowerCase())
        );
      }
      if (dateRange && dateRange[0] && dateRange[1]) {
        const [startDate, endDate] = dateRange.map((date) =>
          date.format("YYYY-MM-DD")
        );
        filtered = filtered.filter((appointment) => {
          const appointmentDate = appointment.followUp_appointmentDate;

          return appointmentDate >= startDate && appointmentDate <= endDate;
        });
      }
      setFilteredDetails(filtered);
    }, 500),
    [details]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterAppointments(searchTerm, dateRange);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates || [null, null]);
    filterAppointments(searchTerm, dates);
  };

  const handleResetFilters = () => {
    setSearchTerm("");
    setDateRange([null, null]);
    setFilteredDetails(details);
  };

  const columns = [
    {
      title: "Appointment No.",
      dataIndex: "appointmentNumber",
      key: "appointmentNumber",
    },
    {
      title: "UserID",
      dataIndex: ["patientId", "userID"],
      key: "userID",
    },
    {
      title: "Patient Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Department(To)",
      key: "department",
      render: (_, record) => {
        const { department, refer } = record;
        if (refer) {
          return refer.toDepartment.department;
        } else {
          return department.department;
        }
      },
    },
    {
      title: "Department(From)",
      key: "department",
      render: (_, record) => {
        const { department, refer } = record;
        if (refer) {
          return department.department;
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <span>{getOrderStatus(status)}</span>,
    },
    {
      title: "Date",
      dataIndex: "followUp_appointmentDate",
      key: "followUp_appointmentDate",
      render: (followUp_appointmentDate) =>
        followUp_appointmentDate ? followUp_appointmentDate : <>-</>,
    },
    {
      title: "Time",
      dataIndex: "followUp_appointmentTime",
      key: "followUp_appointmentTime",
      render: (followUp_appointmentTime) =>
        followUp_appointmentTime ? followUp_appointmentTime : <>-</>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <button
            type="button"
            onClick={() => toggleModal(record)}
            className="px-4 py-2 rounded-md bg-sky-500 text-white hover:bg-sky-600"
          >
            Click here
          </button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">
            FollowUp Appointments
          </h1>

          <div className="mt-3">
            <Input
              placeholder="Search..."
              prefix={<SearchOutlined />}
              allowClear
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: 250 }}
            />
            <RangePicker
              style={{ marginLeft: "1rem" }}
              format="YYYY-MM-DD"
              value={dateRange}
              onChange={handleDateChange}
            />
            <Button
              type="default"
              onClick={handleResetFilters}
              style={{ marginLeft: "1rem" }}
            >
              Reset Filters
            </Button>
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-70"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">
              <appointmentContext.Provider value={contextValue}>
                <ActionFollowUpAppointment />
              </appointmentContext.Provider>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => toggleModal(null)}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewFollowUpAppointments;
