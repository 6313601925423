import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Table, Space, Select, Input, Spin, message, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { getOrderStatus } from "../../lib/helpers";
import { MdRemoveRedEye } from "react-icons/md";
import PDFViewer from "../../lib/constants/pdfViewer";
import ReportViewModal from "../report/report_modal";





const { Option } = Select;

const ViewAppointmentHistory = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [historySearchTerm, setHistorySearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [appointmentHistory, setAppointmentHistory] = useState([]);
  const [singleAppointmentHistory, setSingleAppointmentHistory] = useState([]);
  const [reportHistory, setReportHistory] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);

  const [filteredAppointmentHistory, setFilteredAppointmentHistory] = useState(
    []
  );
  const [selectedType, setSelectedType] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("Prescription");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);


  const EmptyState = ({ message }) => (
    <div className="p-8 text-center">
      <div className="mx-auto h-12 w-12 text-gray-400 mb-4">
        <svg className="w-full h-full" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
      <p className="text-gray-600">{message}</p>
    </div>
  );

  const SectionWrapper = ({ data, columns, emptyMessage }) => {
    if (data.length === 0) return <EmptyState message={emptyMessage} />;

    return (
      <div className="p-4">
        <div className="overflow-x-auto rounded-lg border border-gray-200">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                {columns.map((col, index) => (
                  <th key={index} className="px-4 py-3 text-left text-sm font-semibold text-gray-900">
                    {col.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  {columns.map((col, colIndex) => (
                    <td key={colIndex} className="px-4 py-3 text-sm text-gray-600">
                      {typeof col.accessor === 'function' ? col.accessor(item) : item[col.accessor]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  // for hsitory details like prescription bool
  // prescription
  const [isPrescriptionSelected, setIsPrescriptionSelected] = useState(true);
  // advice
  const [isAdviceSelected, setIsAdviceSelected] = useState(true);
  // diagnosis
  const [isDiagnosisSelected, setIsDiagnosisSelected] = useState(true);
  // report
  const [isReportSelected, setIsReportSelected] = useState(true);
  // summary
  const [isSummarySelected, setIsSummarySelected] = useState(true);


  const staffAuth = localStorage.getItem("staffticket");
  const doctorAuth = localStorage.getItem("doctorticket");
  const config = {
    headers: {
      Authorization: "Bearer " + (staffAuth || doctorAuth),
    },
  };
  const url = staffAuth
    ? `${apiBaseUrl}/patients/history`
    : `${apiBaseUrl}/patients/history/bydr`;

  useEffect(() => {
    fetchData();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  const fetchData = () => {
    setLoading(true);
    axios
      .get(url, config)
      .then((response) => {
        if (response.data.success) {
          console.log("Fetch history data", response.data.data);
          setDetails(response.data.data);
          setFilteredDetails(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
        message.error("Failed to get appointment details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterAppointments = useCallback(
    debounce((searchTerm) => {
      let filtered = details;

      if (searchTerm.trim() !== "") {
        filtered = filtered.filter((user) => {
          const appointmentMatch = user?.appointmentIds?.some(
            (appointment) =>
              appointment?.appointmentNumber
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              appointment?.fullname
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              appointment?.mobile
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              appointment?.address
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase())
          );

          return (
            user?.fullname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user?.userID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user?.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            appointmentMatch
          );
        });

        const expandedKeys = filtered
          .filter(
            (appointment) =>
              appointment.appointmentIds &&
              appointment.appointmentIds.length > 0
          )
          .map((appointment) => appointment._id);
        setExpandedRowKeys(expandedKeys);
      } else {
        setExpandedRowKeys([]);
      }
      setFilteredDetails(filtered);
    }, 500),
    [details, searchTerm]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterAppointments(searchTerm);
  };



  // History section
  const filterHistoryAppointments = useCallback(
    debounce((historySearchTerm, selectedType) => {
      let filtered = appointmentHistory;

      if (historySearchTerm.trim() !== "") {
        filtered = filtered.filter(
          (user) =>
            user?.doctor?.fullname
              ?.toLowerCase()
              .includes(historySearchTerm.toLowerCase()) ||
            user?.department?.department
              ?.toLowerCase()
              .includes(historySearchTerm.toLowerCase())
        );
      }

      if (selectedType) {
        filtered = filtered.filter(
          (appointment) =>
            appointment?.type?.toLowerCase() === selectedType.toLowerCase()
        );
      }

      setFilteredAppointmentHistory(filtered);
    }, 500),
    [appointmentHistory, historySearchTerm, selectedType]
  );

  // History Method section
  const filterHistoryMethodAppointments = useCallback(
    debounce((selectedType) => {
      if (selectedType) {
        switch (selectedType) {
          case "Select History":

            setIsPrescriptionSelected(true);
            setIsAdviceSelected(true);
            setIsDiagnosisSelected(true);
            setIsSummarySelected(true);
            setIsReportSelected(true);
            break;

          case "Prescription":

            setIsPrescriptionSelected(true);
            setIsAdviceSelected(false);
            setIsDiagnosisSelected(false);
            setIsSummarySelected(false);
            setIsReportSelected(false);
            break;

          case "Diagnosis":

            setIsPrescriptionSelected(false);
            setIsAdviceSelected(false);
            setIsDiagnosisSelected(true);
            setIsSummarySelected(false);
            setIsReportSelected(false);
            break;

          case "Report":

            setIsPrescriptionSelected(false);
            setIsAdviceSelected(false);
            setIsDiagnosisSelected(false);
            setIsSummarySelected(false);
            setIsReportSelected(true);
            break;

          case "Advice":

            setIsPrescriptionSelected(false);
            setIsAdviceSelected(true);
            setIsDiagnosisSelected(false);
            setIsSummarySelected(false);
            setIsReportSelected(false);
            break;

          case "Summary":

            setIsPrescriptionSelected(false);
            setIsAdviceSelected(false);
            setIsDiagnosisSelected(false);
            setIsSummarySelected(true);
            setIsReportSelected(false);
            break;

          default:
            break;
        }
      }
    }, 500),
    [selectedType]
  );

  const handleResetFilters = () => {
    setSearchTerm("");
    setExpandedRowKeys([]);
    setFilteredDetails(details);
  };

  const handleHistorySearch = (e) => {
    const historySearchTerm = e.target.value;
    setHistorySearchTerm(historySearchTerm);
    filterHistoryAppointments(historySearchTerm, selectedType);
  };

  // for detials
  const handleHistoryTypeChange = (value) => {
    setSelectedType(value);
    filterHistoryAppointments(historySearchTerm, value);
  };

  const handleHistoryResetFilters = () => {
    setHistorySearchTerm("");
    setSelectedType("");
    setFilteredAppointmentHistory(appointmentHistory);
  };


  // for History detials like prescription
  const handleHistoryMethodChange = (value) => {
    setSelectedMethod(value);
    filterHistoryMethodAppointments(value);
  };

  const handleHistoryMethodResetFilters = () => {
    setSelectedMethod("");
  };

  const appointmentColumns = [
    {
      title: "SN",
      key: "sn",
      render: (_, __, index) => index + 1,
      width: 70,
    },
    {
      title: "Appointment Number",
      dataIndex: "appointmentNumber",
      key: "appointmentNumber",
    },
    {
      title: "Patient Name",
      dataIndex: "fullname",
      key: "patientName",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) =>
        address ? address : <span className="text-red-500">N/A</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <span>{getOrderStatus(status)}</span>,
    },
    {
      title: "Doctor",
      dataIndex: ["appointmentHistory"],
      key: "doctor",
      render: (appointmentHistory) => {
        if (appointmentHistory && appointmentHistory.length > 0) {
          const lastElement = appointmentHistory[appointmentHistory.length - 1];
          return (
            lastElement.doctor?.fullname || (
              <span className="text-red-500">Unassigned</span>
            )
          );
        }
        return <span className="text-red-500">Unassigned</span>;
      },
    },
    {
      title: "Date",
      dataIndex: ["appointmentHistory"],
      key: "date",
      render: (appointmentHistory) => {
        if (appointmentHistory && appointmentHistory.length > 0) {
          const lastElement = appointmentHistory[appointmentHistory.length - 1];

          return lastElement.date ? (
            lastElement.date?.split("T")[0]
          ) : (
            <span className="text-red-500">-</span>
          );
        }
        return <span className="text-red-500">-</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <button
            type="button"
            onClick={() => toggleModal(record)}
            className="px-4 py-1.5 rounded-md bg-sky-500 text-white hover:bg-sky-600"
          >
            View Details
          </button>
        </Space>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <Table
        bordered
        columns={appointmentColumns}
        dataSource={record.appointmentIds}
        rowKey="_id"
        pagination={{ pageSize: 5, style: { marginTop: "24px" } }}
        className="my-3 me-3"
        title={() => "Appointment Details"}
      />
    );
  };

  const columns = [
    {
      title: "UserID",
      dataIndex: "userID",
      key: "userID",
    },
    {
      title: "User's Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      key: "DOB",
      render: (dob) => dob?.split("T")[0],
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) => (address ? address : "-"),
    },
    {
      title: "Appointments",
      key: "appointments",
      render: (_, record) => <span>{record.appointmentIds.length}</span>,
    },
  ];

  const toggleModal = (record) => {
    if (record && record._id) {
      setIsModalOpen(!isModalOpen);

      setAppointmentDetails(record);
      setAppointmentHistory(record.appointmentHistory);
      setFilteredAppointmentHistory(record.appointmentHistory);
    }
    setIsModalOpen(!isModalOpen);
  };

  const toggleHistoryModal = (record) => {
    if (record && record._id) {
      console.log("Data History", record);
      setIsHistoryModalOpen(!isHistoryModalOpen);

      setSingleAppointmentHistory(record);
    }
    setIsHistoryModalOpen(!isHistoryModalOpen);
  };

  const toggleReportModal = (record) => {

    if (record && record._id) {
      console.log("Data History", record);
      setIsReportModalOpen(!isReportModalOpen);

      setReportHistory(record);
    }
    setIsReportModalOpen(!isReportModalOpen);
  };

  const appointmentHistoryColumns = [
    {
      title: "SN",
      key: "sn",
      render: (_, __, index) => index + 1,
      width: 70,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Doctor's Name",
      dataIndex: ["doctor", "fullname"],
      key: "doctor",
      render: (doctor) =>
        doctor ? doctor : <span className="text-red-500">Unassigned</span>,
    },
    {
      title: "Department",
      dataIndex: ["department", "department"],
      key: "department",
      render: (department) =>
        department ? (
          department
        ) : (
          <span className="text-red-500">Unassigned</span>
        ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) =>
        date ? (
          date?.split("T")[0]
        ) : (
          <span className="text-red-500">Unassigned</span>
        ),
    },
    {
      title: "Referred By (dr)",
      dataIndex: ["refer", "fromDoctor", "fullname"],
      key: "fromDoctor",
      render: (doctor) =>
        doctor ? doctor : <span className="text-red-500">-</span>,
    },
    {
      title: "Referred From (Dep)",
      dataIndex: ["refer", "fromDepartment", "department"],
      key: "fromDepartment",
      render: (department) =>
        department ? department : <span className="text-red-500">-</span>,
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) => (address ? address : "-"),
    },
    {
      title: "Note",
      dataIndex: "notes",
      key: "notes",
      width: 320,
      render: (notes, record) => {
        // Check for existing data
        const dataIndicators = [
          { key: 'prescriptions', label: 'Prescription' },
          { key: 'advice', label: 'Advice' },
          { key: 'summary', label: 'Summary' },
          { key: 'diagnosis', label: 'Diagnosis' },
          { key: 'reports', label: 'Report' }
        ].filter(({ key }) => record[key]?.length > 0)
          .map(({ label }) => label);

        // Existing appointment ended handling
        if (notes === "Appointment Ended") {
          let endedBy = "Unknown";
          if (record.endedByDoctor) {
            endedBy = "Dr. " + record.endedByDoctor?.fullname;
          } else if (record.endedByStaff) {
            endedBy = record.endedByStaff?.username + " -staff";
          }

          return (
            <div className="flex flex-col">
              <div>{notes}</div>
              <span className="text-orange-600 italic font-medium">
                by {endedBy}
              </span>
              {dataIndicators.length > 0 && (
                <div className="mt-1 text-xs text-green-600">
                  {dataIndicators.join(' • ')}
                </div>
              )}
            </div>
          );
        }

        // Regular note display with data indicators
        return (
          <div className="flex flex-col">
            <div>{notes}</div>
            {dataIndicators.length > 0 && (
              <div className="mt-1 text-xs text-green-600">
                {dataIndicators.join(' • ')}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <button
            type="button"
            onClick={() => toggleHistoryModal(record)}
            className="px-4 py-1.5 rounded-md bg-sky-500 text-white hover:bg-sky-600"
          >
            View History
          </button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">
            Appointment History
          </h1>
          <div className="flex justify-between mt-3">
            <div>
              <Input
                placeholder="Search..."
                prefix={<SearchOutlined />}
                allowClear
                value={searchTerm}
                onChange={handleSearch}
                style={{ width: 300 }}
              />
              <Button
                type="default"
                onClick={handleResetFilters}
                style={{ marginLeft: "0.5rem" }}
              >
                Reset Filters
              </Button>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            expandable={{
              expandedRowRender,
              expandedRowKeys: expandedRowKeys,
              onExpandedRowsChange: setExpandedRowKeys,
              rowExpandable: (record) =>
                record.appointmentIds && record.appointmentIds.length > 0,
            }}
            pagination={false}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto ">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">
              <div className="m-4">
                <div className="mb-3">
                  <h1 className="text-2xl font-bold decoration-gray-400">
                    Appointment Details{" "}
                    <span className="text-lg font-medium">
                      of {appointmentDetails.fullname}{" "}
                      <span className="italic">
                        ({appointmentDetails.appointmentNumber})
                      </span>
                    </span>
                  </h1>
                  <div className="flex justify-between mt-3">
                    <div>
                      <Input
                        placeholder="Search..."
                        prefix={<SearchOutlined />}
                        allowClear
                        value={historySearchTerm}
                        onChange={handleHistorySearch}
                        style={{ width: 250 }}
                      />
                      <Select
                        placeholder="Select Type"
                        allowClear
                        value={selectedType}
                        onChange={handleHistoryTypeChange}
                        style={{ width: 160, marginLeft: "0.5rem" }}
                      >
                        <Option value="Select Type">Select Type</Option>
                        <Option value="Request">Request</Option>
                        <Option value="Ongoing">Ongoing</Option>
                        <Option value="FollowUp">Follow Up</Option>
                        <Option value="Referred">Referred</Option>
                        <Option value="Ended">Ended</Option>
                      </Select>
                      <Button
                        type="default"
                        onClick={handleHistoryResetFilters}
                        style={{ marginLeft: "0.5rem" }}
                      >
                        Reset Filters
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="overflow-y-auto max-h-[70vh] p-8 pt-0">
                  <Table
                    className="rounded-md shadow-md"
                    dataSource={filteredAppointmentHistory}
                    columns={appointmentHistoryColumns}
                    rowKey="_id"

                    pagination={false}
                    loading={{
                      indicator: <Spin size="large" />,
                      spinning: loading,
                    }}
                  />
                </div>

              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => toggleModal(null)}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-500 text-base font-medium text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


      {isHistoryModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen p-4">
            {/* Backdrop */}
            <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" aria-hidden="true" />

            {/* Modal container */}
            <div className="relative bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] flex flex-col overflow-hidden">
              {/* Header */}
              <div className="flex justify-between items-start p-6 border-b">
                <div>
                  <h2 className="text-2xl font-bold text-gray-900">
                    Medical History Details
                  </h2>
                  <p className="text-gray-600 mt-1">
                    {appointmentDetails.fullname} ({appointmentDetails.appointmentNumber})
                  </p>
                </div>
                <button
                  onClick={() => toggleHistoryModal(null)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Navigation Tabs */}
              <div className="border-b border-gray-200 px-6">
                <nav className="flex space-x-4 overflow-x-auto">
                  {['Prescription', 'Diagnosis', 'Advice', 'Report', 'Summary'].map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setSelectedMethod(tab)}
                      className={`px-4 py-2 text-sm font-medium relative ${selectedMethod === tab
                        ? 'text-sky-600 border-b-2 border-sky-600'
                        : 'text-gray-500 hover:text-gray-700'
                        }`}
                    >
                      {tab}
                    </button>
                  ))}
                </nav>
              </div>

              {/* Scrollable Content Area */}
              <div className="flex-1 overflow-y-auto p-6 min-h-[500px]">
                {!selectedMethod ? (
                  <div className="h-full flex items-center justify-center text-gray-500">
                    <div className="text-center">
                      <div className="mb-4 text-sky-500">
                        <svg className="w-12 h-12 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                        </svg>
                      </div>
                      <p className="text-gray-600">Select a history type to view details</p>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-4">
                    {/* Prescription/Diagnosis/Report content */}
                    {
                      selectedMethod === 'Prescription' && (
                        <div className="space-y-4">
                          {singleAppointmentHistory.prescriptions.length > 0 ? (
                            singleAppointmentHistory.prescriptions.map((item) => (
                              <div key={item._id} className="bg-white rounded-lg border border-gray-200">
                                {/* Prescription Header */}
                                <div className="p-4 bg-gray-50 border-b border-gray-200">
                                  <h3 className="font-semibold text-gray-800">
                                    Prescribed on {formatDate(item.createdAt)}
                                  </h3>
                                  {item.follow_up && (
                                    <p className="text-sm text-gray-600 mt-1">
                                      Follow-up: {formatDate(item.follow_up)}
                                    </p>
                                  )}
                                </div>

                                {/* Medicines Table */}
                                <div className="overflow-x-auto">
                                  <table className="w-full">
                                    <thead className="bg-gray-50 text-gray-700 text-sm">
                                      <tr>
                                        <th className="p-3 text-left min-w-[200px]">Medicine</th>
                                        <th className="p-3 text-left">Affiliate Name</th>
                                        <th className="p-3 text-left">Tablet</th>
                                        <th className="p-3 text-left">Dose</th>
                                        <th className="p-3 text-left">Frequency</th>
                                        <th className="p-3 text-left">Duration</th>
                                        <th className="p-3 text-left">Meal</th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                      {item.medicine.map((medicine, medIdx) =>
                                        medicine.details.map((detail, detIdx) => (
                                          <tr key={`${medIdx}-${detIdx}`} className="hover:bg-gray-50">
                                            {detIdx === 0 && (
                                              <td
                                                className="p-3 font-medium align-top"
                                                rowSpan={medicine.details.length}
                                              >
                                                <div className="flex flex-col">
                                                  <span>{medicine.medicineName}</span>
                                                  {medicine.comment && (
                                                    <span className="text-xs text-gray-500 mt-1">
                                                      {medicine.comment}
                                                    </span>
                                                  )}
                                                </div>
                                              </td>
                                            )}
                                            {detIdx === 0 && (


                                              <td
                                                className="p-3" rowSpan={medicine.details.length}
                                              >
                                                {medicine.affiliateName || '-'}
                                              </td>
                                            )}
                                            <td className="p-3">{detail.tablet || '-'}</td>
                                            <td className="p-3">{detail.dose || '-'}</td>
                                            <td className="p-3">
                                              <div className="flex items-center gap-1">
                                                <span>•</span>
                                                {`${detail.frequency.number}x/${detail.frequency.period}`}
                                                {detail.frequency.period === 'week' && (
                                                  <span className="text-xs text-gray-500 ml-2">
                                                    ({detail.frequency.selectedDays
                                                      .map(day => day.charAt(0).toUpperCase() + day.slice(1))
                                                      .join(', ')})
                                                  </span>
                                                )}
                                              </div>
                                            </td>
                                            <td className="p-3">
                                              {detail.duration.number} {detail.duration.period}
                                              {detail.duration.number > 1 && 's'}
                                            </td>
                                            <td className="p-3 capitalize">{detail.Meal || '-'}</td>
                                          </tr>
                                        ))
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="p-6 text-center text-gray-500">
                              No prescriptions found in this appointment
                            </div>
                          )}
                        </div>
                      )
                    }

                    {/* Diagnosis Section */}
                    {
                      selectedMethod === 'Diagnosis' && (
                        <SectionWrapper
                          data={singleAppointmentHistory.diagnosis}
                          columns={[
                            { header: 'Diagnosis', accessor: 'diagnosis' },
                            { header: 'Doctor', accessor: item => `Dr. ${item.doctorId.fullname}` }
                          ]}
                          emptyMessage="No diagnosis records found"
                        />
                      )
                    }

                    {/* Report Section */}
                    {
                      selectedMethod === 'Report' && (
                        <SectionWrapper
                          data={singleAppointmentHistory.reports}
                          columns={[
                            { header: 'Report Name', accessor: 'reportName' },
                            {
                              header: "Tests",
                              accessor: record => {
                                // Check for both null and empty strings
                                const isEmpty = (value) =>
                                  value === null || value === undefined || value.trim() === "";

                                const subHeadings = record.report
                                  ?.filter(test =>
                                    isEmpty(test.result) &&
                                    isEmpty(test.unit) &&
                                    isEmpty(test.refRange)
                                  )
                                  ?.map(test => test.test.trim())
                                  ?.filter((value, index, self) =>
                                    self.indexOf(value) === index && value !== ""
                                  );

                                return subHeadings?.length > 0
                                  ? subHeadings.join(", ")
                                  : "-";
                              },
                            },
                            {
                              header: 'Actions',
                              accessor: item => (
                                <button
                                  onClick={() => {
                                    setSelectedReport(item.report);
                                    setIsReportModalOpen(true);
                                  }}
                                  className="text-sky-600 hover:text-sky-700 flex items-center"
                                >
                                  <MdRemoveRedEye className="mr-1" /> View
                                </button>
                              )
                            }
                          ]}
                          emptyMessage="No reports available"
                        />
                      )
                    }

                    {
                      selectedMethod === 'Summary' && (
                        <SectionWrapper
                          data={singleAppointmentHistory.summary}
                          columns={[
                            { header: 'Summary', accessor: 'summary' },
                            { header: 'Doctor', accessor: item => `Dr. ${item.doctorId.fullname}` }
                          ]}
                          emptyMessage="No Summary records found"
                        />
                      )
                    }

                    {
                      selectedMethod === 'Advice' && (
                        <SectionWrapper
                          data={singleAppointmentHistory.advice}
                          columns={[
                            { header: 'Advice', accessor: 'advice' },
                            { header: 'Doctor', accessor: item => `Dr. ${item.doctorId.fullname}` }
                          ]}
                          emptyMessage="No Advice records found"
                        />
                      )
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}


      {/* Report Modal */}
      <ReportViewModal
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        report={selectedReport}
      />
    </>
  );
};

export default ViewAppointmentHistory;




