import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { message, Select, Row, Col, Button, Typography } from "antd";
import { testTypesData } from "./report_data";


import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import "../placeholder.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
const calculateAgeNumber = (dob) => {
    if (!dob) return 0;

    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    return age;
};

const { Option } = Select;

const AddSelfReport = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const { appointmentId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [patientDetails, setPatientDetails] = useState([]);
    const [patientStoringValue, setPatientStoringValue] = useState("");
    const [inputAge, setInputAge] = useState("");


    const [patientSelectedId, setPatientSelectedId] = useState(null);
    const [patientSelectedContact, setPatientSelectedContact] = useState("");
    const [patientSelectedName, setPatientSelectedName] = useState("");
    const [address, setAddress] = useState("");
    const [defaultDateBS, setDefaultDateBS] = useState(null);
    const [DOB, setDOBinAD] = useState("");
    const [fullName_state, setFullName] = useState("");
    const [problem_state, setProblem] = useState("");
    const [followUp, setFollowUp] = useState("");
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(true);
    const [doctorDepartment, setDoctorDepartment] = useState("");
    const [actionLoading, setActionLoading] = useState(false);
    const [singleAppointmentData, setSingleAppointmentData] = useState(null);
    const [reportName, setReportName] = useState("");
    // age calculate
    const [isDOBMatched, setIsDOBMatched] = useState(false);
    const [dateBS, setDateBS] = useState("");
    const [dateAD, setDateAD] = useState("");
    // Initialize the ID counter
    const [idCounter, setIdCounter] = useState(0);
    const [reportList, setReportList] = useState([
        {
            test: "",
            result: "",
            unit: "",
            refRange: "",
        },
    ]);

    const getResultColor = (result, refRange) => {
        if (!result || !refRange) return "bg-white";

        // Add your logic for determining if result is within range
        // This is a simple example - modify according to your needs
        const isWithinRange = true; // Add your range checking logic here
        return isWithinRange ? "bg-blue-50" : "bg-red-50";
    };

    const renderReportList = () => (
        <div className="mt-4 space-y-4">
            <table className="w-full">
                <thead>
                    <tr className="border-b">
                        <th className="text-left p-2">Test Name</th>
                        <th className="text-left p-2">Result</th>
                        <th className="text-left p-2 w-1/4">Unit</th>
                        <th className="text-left p-2">Ref-Range</th>
                        <th className="text-left p-2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {reportList.map((medicine, index) => (
                        <tr className={`border-b ${isTitleTest(medicine) ? 'bg-gray-50' : ''}`}>
                            <td className="p-2">
                                <input
                                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:border-transparent transition-all duration-200"
                                    type="text"
                                    required
                                    placeholder="eg.. LFT Test"
                                    value={medicine.test}
                                    onChange={(e) => handleInputChange(index, "test", e.target.value)}

                                />
                            </td>
                            <td className="p-2">
                                <input
                                    className={`border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ${getResultColor(medicine.result, medicine.refRange)}`}
                                    type="text"
                                    placeholder="eg.. 2.76"
                                    value={medicine.result}
                                    onChange={(e) => handleInputChange(index, "result", e.target.value)}

                                />
                            </td>
                            <td className="p-2">
                                <Select
                                    showSearch
                                    className="w-full"
                                    placeholder="Select unit"
                                    value={medicine.unit}
                                    onChange={(value) => handleInputChange(index, "unit", value)}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    allowClear
                                    popupClassName="rounded-md shadow-lg"
                                >
                                    {unitOptions.map((unit) => (
                                        <Option key={unit} value={unit}>
                                            {unit}
                                        </Option>
                                    ))}
                                </Select>
                            </td>
                            <td className="p-2">
                                <textarea
                                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    placeholder="Range"
                                    value={medicine.refRange}
                                    onChange={(e) => handleInputChange(index, "refRange", e.target.value)}
                                    style={{
                                        maxHeight: "75px",
                                        minHeight: "38px",
                                        resize: "none",
                                        padding: "9px",
                                        boxSizing: "border-box",
                                        fontSize: "15px"
                                    }}
                                />
                            </td>
                            <td className="p-2">
                                <IconButton
                                    onClick={() => removeMedicine(index)}
                                    color="danger"
                                    aria-label="minus button"
                                    className="hover:bg-red-100 transition-colors duration-200"
                                >
                                    <AiFillMinusCircle className="text-red-500 h-5 w-5" />
                                </IconButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
    {/* {reportList.map((medicine, index) => (

        <div
            key={index}
            className={`flex flex-wrap -mx-3 mb-2 items-center p-4 rounded-lg transition-colors duration-200 ${isTitleTest(medicine) ? 'bg-gray-50' : ''
                }`}
        >
            <div className="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                    Test Name
                </label>
                <input
                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                    type="text"
                    required
                    placeholder="eg.. LFT Test"
                    value={medicine.test}
                    onChange={(e) => handleInputChange(index, "test", e.target.value)}
                />
            </div>

            <div className="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                    Result
                </label>
                <input
                    className={`border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ${getResultColor(medicine.result, medicine.refRange)
                        }`}
                    type="text"
                    placeholder="eg.. 2.76"
                    value={medicine.result}
                    onChange={(e) => handleInputChange(index, "result", e.target.value)}
                />
            </div>

            <div className="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                    Unit
                </label>
                <Select
                    showSearch
                    className="w-full"
                    placeholder="Select unit"
                    value={medicine.unit}
                    onChange={(value) => handleInputChange(index, "unit", value)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    popupClassName="rounded-md shadow-lg"
                >
                    {unitOptions.map((unit) => (
                        <Option key={unit} value={unit}>
                            {unit}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="w-full md:w-2/6 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                    Ref-Range
                </label>
                <textarea
                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                    placeholder="Range"
                    value={medicine.refRange}
                    onChange={(e) => handleInputChange(index, "refRange", e.target.value)}
                    style={{
                        maxHeight: "75px",
                        minHeight: "38px",
                        resize: "none",
                        padding: "9px",
                        boxSizing: "border-box",
                        fontSize: "15px"
                    }}
                />
            </div>

            <div className="md:w-1/10 px-3 flex items-end mb-6 md:mb-0">
                <IconButton
                    onClick={() => removeMedicine(index)}
                    color="danger"
                    aria-label="minus button"
                    className="hover:bg-red-100 transition-colors duration-200"
                >
                    <AiFillMinusCircle className="text-red-500 h-5 w-5" />
                </IconButton>
            </div>
        </div>
    ))} */}
    const [selectedTypes, setSelectedTypes] = useState([]);

    const [unitOptions] = useState([
        'mg/dl',
        'mg/l',
        'Between 4-5%',
        'Mm/hr',
        'gm/dl',
        '/cumm',
        'millions/cumm',
        '/Hpf',
        'g/dl',
        'mmol/L',
        'µmol/L',
        'mEq/L',
        'ng/mL',
        'pg/mL',
        'U/L',
        'IU/L',
        '%',
        'K/µL',
        'cells/mcL',
        'mm/hr',
        'fL',
        'pg',
        'g/L',
        'ratio'
    ]);

    const [testTypes] = useState(testTypesData);
    const [details, setDetails] = useState([]);

    const config = {
        headers: {
            Authorization:
                "Bearer " +
                localStorage.getItem("labreporterticket"),
        },
    };



    /// In this logic, we are checking if the data (item) is in appointmenthistory or not. But since report can be added
    /// even when the status is ended, we cannot use the same logic as on prescription. Since in BE hat we are doing is,
    /// if the appointment is of status ended or referes with doctor null, then the report is added on appointment history 
    /// until we find a history with (ongoing) OR (Refered with doctor not null) OR (followUP). Then the data is added on that
    /// history. So we now need to compare the data as the same way . So we are going through a loop only if the latest appointment
    /// history is of ended or referred with doctor null until we find the required history. If not the latest is used




    // this is for dropdown of test for choosing test
    const handleTestTypeChange = (value) => {
        if (!value) return;

        if (!selectedTypes.includes(value)) {
            setSelectedTypes([...selectedTypes, value]);

            // Generate new report name
            const newReportName = selectedTypes.length > 0
                ? reportName + "," + testTypes[value].reportName
                : testTypes[value].reportName;
            setReportName(newReportName);

            // Replace empty default field if it's the only one
            const isDefaultEmpty = reportList.length === 1 &&
                Object.values(reportList[0]).every(v => v === "");

            const newFields = testTypes[value].fields;
            setReportList(isDefaultEmpty ? newFields : [...reportList, ...newFields]);
        }
    };
    const removeTestType = (typeToRemove) => {
        const updatedTypes = selectedTypes.filter(type => type !== typeToRemove);
        setSelectedTypes(updatedTypes);

        const newReportName = updatedTypes
            .map(type => testTypes[type].reportName)
            .join(",");
        setReportName(newReportName);

        // Calculate field counts for removed type
        const removedTypeFieldCount = testTypes[typeToRemove].fields.length;
        const currentIndex = selectedTypes.indexOf(typeToRemove);
        const startIndex = selectedTypes
            .slice(0, currentIndex)
            .reduce((acc, type) => acc + testTypes[type].fields.length, 0);

        // Remove fields for the specific type
        const updatedReportList = [
            ...reportList.slice(0, startIndex),
            ...reportList.slice(startIndex + removedTypeFieldCount)
        ];

        setReportList(updatedReportList.length ? updatedReportList : [{
            test: "",
            result: "",
            unit: "",
            refRange: ""
        }]);
    };
    const handleDateInBS = ({ bsDate, adDate }) => {
        setDateBS(bsDate);
        setDateAD(adDate);
        setDefaultDateBS(bsDate);
    };
    const handleClear = () => {
        setDefaultDateBS(null);
        setDateBS(null);
    };

    /// -------------------- TITLE TEST
    const isTitleTest = (test) => {
        return test.test !== "" && test.result === "" && test.unit === "" && test.refRange === "";
    };

    const handleActionLoading = (value) => {
        setActionLoading(value);
    };

    // handle changes for patient dropdown..
    const handlePatientInputChange = (value, option) => {
        console.log("Value:", value);
        console.log("Option:", option);
        if (option) {
            setPatientStoringValue(option);
            setPatientSelectedId(option.value);
            setPatientSelectedContact(option.contact);
            setPatientSelectedName(option.name_value);
            setAddress(option.address);
            const convertedDOB = option.DOB?.split("T")[0];
            const age = calculateAgeNumber(convertedDOB);
            setInputAge(age);
            setDOBinAD(convertedDOB);
        }
    };

    useEffect(() => {
        axios
            .get(`${apiBaseUrl}/viewpatientsLabReporter`, config)
            .then((response) => {
                if (response.data.success) {
                    const options = response.data.data.map((patient) => ({
                        value: patient._id,
                        name_value: `${patient.fullname}`,
                        label: `${patient.fullname} (${patient.phone})`,
                        contact: patient.phone,
                        DOB: patient.DOB,
                        DOBinBS: patient.DOBinBS,
                        address: patient.address,
                        children: `${patient.fullname} ${patient.phone} ${patient.address}`,
                    }));

                    setPatientDetails(options);

                }
            })
            .catch((e) => {
                console.error("Failed to get user details:", e);
                message.error("Failed to get user details! Please try again.");
            });
    }, []);

    const handleAgeChange = (e) => {
        const inputAge = e.target.value;
        setInputAge(inputAge);

        try {
            if (inputAge) {
                const today = new Date();
                const birthYear = today.getFullYear() - inputAge;
                const birthDate = new Date(
                    birthYear,
                    today.getMonth(),
                    today.getDate()
                );
                const calculatedDOB = birthDate.toISOString().split("T")[0];
                setDOBinAD(calculatedDOB);
            } else {
                setDOBinAD("");
            }
        } catch (error) {
            message.error("Please enter valid age.");
        }
    };



    const addReport = async (e) => {
        e.preventDefault();
        handleActionLoading(true);


        // Check if the followUp is empty or default date
        const isReportNameEmpty = reportName === "";

        // Check if the reportList is empty or contains only an empty default entry
        const isReportListEmpty =
            reportList.length === 0 ||
            JSON.stringify(reportList[0]) ===
            JSON.stringify({
                test: "",
                result: "",
                unit: "",
                refRange: "",
            });

        // Check if each test is valid based on the specified rule
        const isReportListValid = reportList.every((item, index) => {
            if (item.test && !item.result) {
                // Check if any subsequent test has a result
                const subsequentTestHasResult = reportList
                    .slice(index + 1)
                    .some(nextItem => nextItem.test && nextItem.result);
                return subsequentTestHasResult;
            }
            return true;
        });



        // Helper function to check for partially filled medicine entries
        const isMedicinePartiallyFilled = (medicine) => {
            const { test, result, unit, refRange } = medicine;
            const allFieldsEmpty = !test && !result && !unit && !refRange;
            const allFieldsFilled = test;
            return !(allFieldsEmpty || allFieldsFilled);
        };
        // Check if any medicine in the list is partially filled
        const hasPartiallyFilledMedicine = reportList.some(
            isMedicinePartiallyFilled
        );
        const data = {
            report: reportList,
            reportName: reportName,
            self: true,
        };
        if (isReportNameEmpty && isReportListEmpty) {
            handleActionLoading(false);

            message.error("Both medicine and followup cannot be empty");
        } else if (!isReportListValid) {
            handleActionLoading(false);
            message.error("If a test name is entered, at least one test below it must have a result filled in.");

        }

        else if (hasPartiallyFilledMedicine) {
            handleActionLoading(false);

            message.error("Medicine fields cannot be patially filled");
        } else {
            try {
                let response;
                if (localStorage.getItem("labreporterticket")) {
                    response = await axios.post(
                        `${apiBaseUrl}/add-self-report/${patientSelectedId}`,
                        data,
                        config
                    );
                }
                if (response.data.success == true) {

                    message.success("Report Added", 0.6, function onClose() {
                        navigate(`/dashboard/view/labselfreports`);
                    });
                } else {
                    message.error("Failed To add report");
                }
            } catch (error) {
                console.error("Failed to add report:", error);
                message.error("Failed To add report! Please try again.");
            } finally {
                handleActionLoading(false);
            }
        }
    };
    const addTest = () => {
        setReportList([
            ...reportList,
            {
                test: "",
                result: "",
                unit: "",
                refRange: "",
            },
        ]);
    };


    // Function to remove a group of tests starting from startIndex
    const removeGroup = (startIndex) => {
        let endIndex = reportList.length;
        for (let i = startIndex + 1; i < reportList.length; i++) {
            if (isTitleTest(reportList[i])) {
                endIndex = i;
                break;
            }
        }
        const updatedList = [
            ...reportList.slice(0, startIndex),
            ...reportList.slice(endIndex)
        ];
        setReportList(updatedList);
    };

    const removeMedicine = (index) => {
        if (isTitleTest(reportList[index])) {
            // Find the next title test
            let endIndex = reportList.length;
            for (let i = index + 1; i < reportList.length; i++) {
                if (isTitleTest(reportList[i])) {
                    endIndex = i;
                    break;
                }
            }
            // Remove from index to endIndex - 1
            const newList = [
                ...reportList.slice(0, index),
                ...reportList.slice(endIndex)
            ];
            setReportList(newList);
        } else {
            // Remove only the single test
            const newList = [...reportList];
            newList.splice(index, 1);
            setReportList(newList);
        }
    };
    const titleTestIndices = reportList.reduce((acc, test, index) => {
        if (isTitleTest(test)) {
            acc.push({ "index": index, "type": reportList[index].test });
        }
        return acc;
    }, []);

    const handleInputChange = (index, key, value) => {
        const updatedList = [...reportList];
        updatedList[index][key] = value;
        setReportList(updatedList);
    };

    const handleReportChange = (value) => {
        setReportName(value);
    }



    return (

        <>
            {actionLoading && <LoadingScreen />}

            <div className="mb-3">
                <h1 className="text-3xl font-bold decoration-gray-400">Add Report</h1>
            </div>

            <div className="flex items-start">


                {/* form card */}
                <form className="forbox w-full max-w-7xl bg-white bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"

                    onSubmit={addReport}>
                    <div className="block p-6 items-center bg-white border border-gray-200 rounded-lg shadow">
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <label className="text-base">Patient Name</label>
                                <div>
                                    <Select
                                        placeholder="Select a Patient"
                                        showSearch
                                        allowClear
                                        required
                                        className="w-full h-10 mt-0.2"
                                        popupMatchSelectWidth={false}
                                        options={patientDetails}
                                        onChange={handlePatientInputChange}
                                        value={patientStoringValue}
                                        style={{ whiteSpace: 'normal' }}
                                        optionFilterProp="children"

                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <div>
                                    <label className="text-base">Contact</label>
                                    <input
                                        value={patientSelectedContact}
                                        onChange={(e) => {
                                            setPatientSelectedContact(e.target.value);
                                        }}
                                        className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                                        name="title"
                                        placeholder="Contact"
                                        required
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <label className="text-base">DOB (in BS)</label>
                                    <Calendar
                                        key={defaultDateBS}
                                        onChange={handleDateInBS}
                                        theme="blue"
                                        defaultDate={defaultDateBS}
                                        hideDefaultValue={!defaultDateBS}
                                        language="en"
                                        placeholder="Select Date(BS)"
                                        className="border border-gray-300 border py-1.5 px-3 rounded-md text-gray-800 custom-placeholder w-full"
                                    />
                                    {dateBS && (
                                        <AiOutlineCloseCircle
                                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
                                            onClick={handleClear}
                                        />
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <div>
                                    <label className="text-base">Address</label>
                                    <input
                                        value={address}
                                        onChange={(e) => {
                                            setAddress(e.target.value);
                                        }}
                                        className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                                        name="title"
                                        placeholder="Address"
                                        required
                                    />
                                </div>
                            </Col>
                            <Col span={6}>
                                <div>
                                    <label className="text-base ms-1">DOB (in AD)</label>
                                    <input
                                        id="DOB"
                                        value={DOB}
                                        type="date"
                                        onChange={(e) => {
                                            setDOBinAD(e.target.value);
                                        }}
                                        max={new Date().toISOString().split("T")[0]}
                                        className="border-solid border-gray-300 border h-10 py-2 px-2 w-full rounded-md text-gray-700 date-picker"
                                        name="dob"
                                        placeholder="Select Date"
                                    />
                                </div>
                            </Col>
                            <Col span={6}>
                                <div>
                                    <label className="text-base ms-1">Age(Years)</label>
                                    <input
                                        onChange={handleAgeChange}
                                        value={inputAge}
                                        className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md"
                                        name="age"
                                        type="number"
                                        placeholder="Age"
                                        required
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="w-full md:w-1/4 mb-6 md:mb-0">
                            <label
                                className="block uppercase tracking-wide text-xs font-bold mb-2"
                                htmlFor="test-type"
                            >
                                Test Type
                            </label>
                            <Select
                                showSearch
                                className="w-full"
                                placeholder="Select test type"
                                onChange={handleTestTypeChange}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={null}
                                allowClear
                            >
                                {Object.keys(testTypes).map((type) => (
                                    <Option key={type} value={type}>
                                        {type}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="flex flex-wrap gap-2 mt-2">
                            {selectedTypes.map((type) => (
                                <div
                                    key={type}
                                    className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md"
                                >
                                    <span className="mr-1">{type}</span>
                                    <button
                                        type="button"
                                        onClick={() => removeTestType(type)}
                                        className="ml-1 text-gray-500 hover:text-gray-700"
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                        </div>
                        <p className="text-xl mb-1 font-bold tracking-tight text-gray-800">
                            Add Report
                        </p>
                        <div className="font-normal text-gray-700">

                            <div className="w-full md:w-1/4  mb-6 md:mb-0">
                                <label
                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                    for="grid-zip"
                                >
                                    Report Name
                                </label>
                                <input
                                    className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-300"
                                    type="text"
                                    required
                                    placeholder="eg.. Report Name"
                                    value={reportName}
                                    onChange={(e) =>
                                        handleReportChange(e.target.value)
                                    }
                                />
                            </div>
                            <div className="flex flex-wrap gap-2 mt-2">
                                {titleTestIndices.map((type) => (
                                    <div
                                        key={type.type}
                                        className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md"
                                    >
                                        <span className="mr-1">{type.type}</span>
                                        <button
                                            type="button"
                                            onClick={() => removeGroup(type.index)}
                                            className="ml-1 text-gray-500 hover:text-gray-700"
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}
                            </div>
                            {renderReportList()}
                            <IconButton
                                onClick={addTest}
                                color="primary"
                                aria-label="add button"
                            >
                                Add Test field
                                <HiPlusCircle />
                            </IconButton>


                            <button
                                id="addStaffBtn"
                                className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
export default AddSelfReport;
