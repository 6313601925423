import React, { useState, useEffect } from "react";
import { Button, Divider, message } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import "./consultationPrintout.css";
import moment from "moment-timezone";
import { calculateAge } from "../../lib/constants/calculateAge";

const ConsultationPrintout = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();
  const [appointmentHistory, setAppointmentHistory] = useState("");
  const [DOB, setDOB] = useState("");
  const [age, setAge] = useState("");
  const [loading, setLoading] = useState("");
  const [details, setDetails] = useState("");
  let calculatedAge;

  useEffect(() => {
    calculatedAge = calculateAge(DOB);

    if (
      calculatedAge !== undefined &&
      calculatedAge !== "Invalid date of birth"
    ) {
      setAge(calculatedAge);
    } else {
      setAge("");
    }
  }, [DOB]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    const url = `${apiBaseUrl}/appointment/${appointmentId}`;

    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          const data = response.data.data;
          setDetails(data);
          setDOB(data.DOB);
          setAppointmentHistory(data.appointmentHistory);
          console.log(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
        message.error("Failed to get appointment details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePrint = () => {
    const printableContent =
      document.getElementById("printableContent").innerHTML;

    // Create a temporary div element
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = printableContent;
    // Replace the body content with the printable content
    document.body.innerHTML = tempDiv.innerHTML;

    window.print();
    window.location.replace("/dashboard/viewappointment");
  };

  const convertToNepaliTime = (utcTime) => {
    return moment.utc(utcTime).tz("Asia/Kathmandu").format("YYYY-MM-DD h:mm A");
  };

  return (
    <div>
      <div id="printableContent" className="print-content">
        <div className="container p-4 place-content-center">
          <div className="mb-3">
            <div className="text-center">
              <span className="text-2xl">Tulsi MultiSpeciality Clinic</span>
              <p>
                <EnvironmentOutlined /> Avm chowk 05, Lalitpur 44600
              </p>
              <p>
                <PhoneOutlined /> 01-5914294
              </p>
            </div>
          </div>

          <div className="flex justify-between mb-8">
            <div className="flex flex-col text-start">
              {/* <p>Patient ID: {tableData?.appointment?.patientId?.userID}</p> */}
              <p>Name: {details?.fullname}</p>
              <p>
                Age/ Sex: {age}
                {"/"} {details?.gender}
              </p>
              <p>Address: {details?.address}</p>
            </div>
            <div className="flex flex-col text-end">
              <p>Appointment No: {details?.appointmentNumber}</p>

              <p>
                Date:
                {convertToNepaliTime(details?.date)}
              </p>
            </div>
          </div>

          <Divider />

          <div className="generated-by text-end me-5 mt-3">
            {/* <p>User: {tableData?.generatedBy?.username}</p> */}
          </div>
        </div>
      </div>
      {/* Print Button */}
      <Button
        type="primary"
        onClick={handlePrint}
        className="mt-4 bg-sky-600 hover:bg-sky-700 text-white border shadow font-semibold text-md rounded-md"
      >
        Print Invoice
      </Button>
    </div>
  );
};

export default ConsultationPrintout;
