import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import Login from "./Login";
import Dashboard from "../pages/dashboard/Dashboard.jsx";
import Layout from "../components/shared/Layout";
import AddStaff from "../pages/AddStaff";
import ViewStaff from "../pages/ViewStaff";
import ChangePassword from "../pages/ChangePassword";
import User from "../pages/User";
import Doctor from "../pages/Doctor";
import AddDoctor from "../pages/AddDoctor";
import Department from "../pages/Department";
import CreateDepartment from "../pages/CreateDepartment";
import CheckRefers from "../pages/Refer";
import Medicine from "../pages/Medicine";
import UpdateRefer from "../pages/UpdateRefer";
import ViewPharmaStaff from "../pages/ViewPharmaStaff";
import AddLabReporter from "../pages/AddLabReporter";
import ViewLabReporter from "../pages/ViewLabReporter";
import ViewAppointment from "../pages/ViewAppointments";
import AddPharmaStaff from "../pages/AddPharmaStaff";
import AddMedicine from "../pages/AddMedicine";
import AddPatient from "../pages/AddPatient";
import UpdateDepartment from "../pages/UpdateDepartment";
import ViewOngoingAppointment from "../pages/ViewOngoingAppointment";
import ViewReferralAppointment from "../pages/ReferredAppointments";
import ViewEndedAppointmentByDoctor from "../pages/EndedAppointment";
import ReferDepartmentByDoctor from "./doctor_modal/ongoing/refer_department";
import ViewReferralAppointmentToMe from "../pages/referredToMeAppointment";
// import AddLabReport from "../pages/AddReport";
import PdfViewPage from "../pages/viewReportPdf.jsx";
import AddSymptoms from "../pages/AddSymptoms.jsx";
import ViewSymptoms from "../pages/ViewSymptoms.jsx";
import UpdatePrescription from "./doctor_modal/ongoing/update_prescription.jsx";
import AddPrescription from "./doctor_modal/ongoing/add_prescription.jsx";
import AddAppointmentTime from "../pages/addAppointmentTime.jsx";
import ViewAppointmentDateTime from "../pages/ViewAppointmentDateTime.jsx";
import UpdateAppointmentDateTime from "../pages/UpdateAppointmentTime.jsx";
import ViewLabReports from "../pages/ViewReports.jsx";
import ViewReportByAppointmentId from "../pages/ViewReportWrtAppointment.jsx";
import UpdateLabReport from "../pages/UpdateLabReport.jsx";
import UpdateMedicine from "../pages/UpdateMedicine.jsx";
import ViewFollowUpAppointments from "../pages/ViewFollowUpAppointments.jsx";
import ViewPrescription from "../pages/viewPrescription.jsx";
import ViewInProgressAppointment from "../pages/ViewInProgressAppointments.jsx";
import ViewCancelStatusAppointment from "../pages/ViewCancelStatusAppointments.jsx";
import ViewCompletedAppointment from "../pages/viewCompletedAppointmentsStaff.jsx";
import ViewStaffReferredAppointment from "../pages/ViewStaffReferredAppointments.jsx";
import AddNewAppointmentStaff from "../pages/AddNewAppointmentByStaff.jsx";
import UpdateNewAppointmentStaff from "../pages/UpdateNewAppointment.jsx";
import ReferredDepartmentByStaff from "./staff_modal/referredDepartment.jsx";
import ChangeReferredDepartmentByStaff from "./staff_modal/changeDepartement.jsx";
import UpdateReferredAndFollowUpAppointmentDateTime from "../pages/updateReferAndFollowupDateTime.jsx";
import AddFollowUpDateTime from "./staff_modal/addFollowUpDateTime.jsx";
import BillsPage from "../pages/viewBillsPage.jsx";
import AddBill from "./billing/AddBill.jsx";
import PrintInvoice from "./billing/printInvoice.jsx";
import UpdateBill from "./billing/UpdateBill.jsx";
import UpdateDoctor from "../pages/UpdateDoctor.jsx";
import UpdateSymptom from "../pages/updateSymptom.jsx";
import UpdateStaff from "../pages/updateStaff.jsx";
import UpdateLabReporter from "../pages/UpdateLabReporter.jsx";
import UpdatePharmaStaff from "../pages/UpdatePharmaStaff.jsx";
import UnauthorizedPage from "../lib/constants/unauthorized.jsx";
import PageNotFound from "../lib/constants/PageNotFound.jsx";
import UpdatePatient from "../pages/UpdatePatient.jsx";
import ProtectedRoute from "../lib/constants/routeProtection.jsx";
import UserProfile from "../pages/UserProfile.jsx";
import ViewDoctorMedicineByAdmin from "../pages/viewDoctorMedicineAdmin.jsx";
import AddDoctorMedicineAdmin from "../pages/addDoctorMedicineByAdmin.jsx";
import UpdateDoctorMedicineAdmin from "../pages/updateDoctorMedicineAdmin.jsx";
import ManagePasswordAdmin from "../pages/managePasswordsAdmin.jsx";
import ViewAppointmentRequestFromPatient from "../pages/viewAppointentRequestFromPatient.jsx";
import UpdateAppointmentRequestByStaff from "../pages/updateAppointmentRequestByStaff.jsx";
import ViewAdBanner from "../pages/adBanner/ViewAdBanner.jsx";
import AddAdBanner from "../pages/adBanner/addAdBanner.jsx";
import UpdateAdBanner from "../pages/adBanner/updateAdBanner.jsx";
import ViewLabBillsPage from "../pages/medicalBillingArchives/viewLabBillsStaff.jsx";
import AddMedicalCategory from "../pages/medicalBillingArchives/medicalCategory/addMedicalBillCategory.jsx";
import UpdateMedicalCategory from "../pages/medicalBillingArchives/medicalCategory/updateMedicalBillCategory.jsx";
import ViewMedicalTestCategory from "../pages/medicalBillingArchives/medicalCategory/ViewMedicalBillCategory.jsx";
import ViewMedicalTestLists from "../pages/medicalBillingArchives/MedicalBillAdmin/viewMedicalTests.jsx";
import AddMedicalTestList from "../pages/medicalBillingArchives/MedicalBillAdmin/addMedicalTest.jsx";
import UpdateMedicalTestList from "../pages/medicalBillingArchives/MedicalBillAdmin/updateMedicalTest.jsx";
import ViewDetailedBill from "../pages/medicalBillingArchives/viewdetailedBill.jsx";
import CreateTestBill from "../pages/medicalBillingArchives/createLabBill.jsx";
import UpdateTestBill from "../pages/medicalBillingArchives/updateLabBill.jsx";
import StaffBillPrintInvoice from "../pages/medicalBillingArchives/printInvoice.jsx";
import ViewAppointmentHistory from "../pages/appointmentHistory/viewAppointmentHistory.jsx";
import ViewDiagnosis from "../pages/diagnosis/viewDiagnosis.jsx";
import ConsultationPrintout from "../pages/printout/consultationPrintout.jsx";
import ViewAdvice from "../pages/advice/viewAdvice.jsx";
import ViewSummary from "../pages/summary/viewSummary.jsx";
import HistoryPrintout from "../pages/printout/historyPrintout.jsx";
import OnlyViewDiagnosis from "../pages/diagnosis/onlyViewDiagnosis.jsx";
import OnlyViewAdvice from "../pages/advice/onlyViewAdvice.jsx";
import OnlyViewSummary from "../pages/summary/onlyViewSummary.jsx";
import AddReport from "../pages/report/add_report.jsx";
import UpdateReport from "../pages/report/update_report.jsx";
import SendMessage from "./message/send-message.jsx";
import ReportPrintout from "../pages/printout/reportPrintout.jsx";
import ViewLabSelfReports from "../pages/ViewSelfReports.jsx";
import SelfReportPrintout from "../pages/printout/selfReportPrintout.jsx";
import AddSelfReport from "../pages/report/add_self_report.jsx";

const Mid = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/pagenotfound" element={<PageNotFound />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              roles={["admin", "doctor", "staff", "labreporter", "pharma"]}
              component={Layout}
            />
          }
        >
          <Route
            index
            element={
              <ProtectedRoute
                roles={["admin", "doctor", "staff", "labreporter", "pharma"]}
                component={Dashboard}
              />
            }
          />

          <Route
            path="/dashboard/reset/password/:userId"
            element={
              <ProtectedRoute
                roles={["admin"]}
                component={ManagePasswordAdmin}
              />
            }
          />

          {/* Doctor And Staff */}
          <Route
            path="/dashboard/viewreport/:appointmentId"
            element={
              <ProtectedRoute
                roles={["doctor", "staff", "labreporter"]}
                component={ViewReportByAppointmentId}
              />
            }
          />

          {/* For viewing and adding */}
          <Route
            path="/dashboard/diagnosis/:appointmentId"
            element={
              <ProtectedRoute
                roles={["doctor", "staff"]}
                component={ViewDiagnosis}
              />
            }
          />
          {/* For Viewing only */}
          <Route
            path="/dashboard/onlyViewDiagnosis/:appointmentId"
            element={
              <ProtectedRoute
                roles={["doctor", "staff"]}
                component={OnlyViewDiagnosis}
              />
            }
          />

          {/* For viewing and adding */}
          <Route
            path="/dashboard/advice/:appointmentId"
            element={
              <ProtectedRoute
                roles={["doctor", "staff"]}
                component={ViewAdvice}
              />
            }
          />
          {/* For Viewing only */}
          <Route
            path="/dashboard/onlyViewAdvice/:appointmentId"
            element={
              <ProtectedRoute
                roles={["doctor", "staff"]}
                component={OnlyViewAdvice}
              />
            }
          />

          {/* For viewing and adding */}
          <Route
            path="/dashboard/summary/:appointmentId"
            element={
              <ProtectedRoute
                roles={["doctor", "staff"]}
                component={ViewSummary}
              />
            }
          />
          {/* For Viewing only */}
          <Route
            path="/dashboard/onlyViewSummary/:appointmentId"
            element={
              <ProtectedRoute
                roles={["doctor", "staff"]}
                component={OnlyViewSummary}
              />
            }
          />

          {/* Admin */}
          <Route
            path="/dashboard/addstaff"
            element={<ProtectedRoute roles={["admin"]} component={AddStaff} />}
          />
          <Route
            path="/dashboard/viewstaff"
            element={<ProtectedRoute roles={["admin"]} component={ViewStaff} />}
          />
          <Route
            path="/dashboard/view/ad-banner"
            element={
              <ProtectedRoute roles={["admin"]} component={ViewAdBanner} />
            }
          />
          <Route
            path="/dashboard/add/ad-banner"
            element={
              <ProtectedRoute roles={["admin"]} component={AddAdBanner} />
            }
          />
          <Route
            path="/dashboard/update/ad-banner/:bannerId"
            element={
              <ProtectedRoute roles={["admin"]} component={UpdateAdBanner} />
            }
          />

          <Route path="/dashboard/send-message" element={
            <ProtectedRoute roles={["admin"]} component={SendMessage} />
          } />


          {/* medical test category */}
          <Route
            path="/dashboard/viewmedicaltestcategory"
            element={
              <ProtectedRoute
                roles={["admin"]}
                component={ViewMedicalTestCategory}
              />
            }
          />
          <Route
            path="/dashboard/add/medical_category"
            element={
              <ProtectedRoute
                roles={["admin"]}
                component={AddMedicalCategory}
              />
            }
          />
          <Route
            path="/dashboard/update/medical_category/:categoryId"
            element={
              <ProtectedRoute
                roles={["admin"]}
                component={UpdateMedicalCategory}
              />
            }
          />

          {/* Medical Test Lists */}
          <Route
            path="/dashboard/viewmedicaltestlists"
            element={
              <ProtectedRoute
                roles={["admin"]}
                component={ViewMedicalTestLists}
              />
            }
          />
          <Route
            path="/dashboard/add/medical_test"
            element={
              <ProtectedRoute
                roles={["admin"]}
                component={AddMedicalTestList}
              />
            }
          />
          <Route
            path="/dashboard/update/medical_test/:mId"
            element={
              <ProtectedRoute
                roles={["admin"]}
                component={UpdateMedicalTestList}
              />
            }
          />

          {/* Doctor */}
          <Route
            path="/dashboard/view_reportdr"
            element={
              <ProtectedRoute
                roles={["doctor", "staff", "labreporter"]}
                component={PdfViewPage}
              />
            }
          />
          <Route
            path="/dashboard/viewOngoingAppointmentbyDoctor"
            element={
              <ProtectedRoute
                roles={["doctor"]}
                component={ViewOngoingAppointment}
              />
            }
          />
          <Route
            path="/dashboard/view/followup/appointments"
            element={
              <ProtectedRoute
                roles={["doctor"]}
                component={ViewFollowUpAppointments}
              />
            }
          />
          <Route
            path="/dashboard/viewReferredAppointmentbyDoctor"
            element={
              <ProtectedRoute
                roles={["doctor"]}
                component={ViewReferralAppointment}
              />
            }
          />
          <Route
            path="/dashboard/viewReferredAppointment/toDoctor"
            element={
              <ProtectedRoute
                roles={["doctor"]}
                component={ViewReferralAppointmentToMe}
              />
            }
          />
          <Route
            path="/dashboard/viewEndedAppointmentbyDoctor"
            element={
              <ProtectedRoute
                roles={["doctor"]}
                component={ViewEndedAppointmentByDoctor}
              />
            }
          />
          <Route
            path="/dashboard/viewOngoingAppointmentbyDoctor/refer_department/:appointmentId/:departmentId"
            element={
              <ProtectedRoute
                roles={["doctor"]}
                component={ReferDepartmentByDoctor}
              />
            }
          />
          <Route
            path="/dashboard/viewOngoingAppointmentbyDoctor/add_prescription/:appointmentId"
            element={
              <ProtectedRoute
                roles={["doctor", "staff"]}
                component={AddPrescription}
              />
            }
          />
          <Route
            path="/dashboard/viewOngoingAppointmentbyDoctor/update_prescription/:prescriptionId"
            element={
              <ProtectedRoute
                roles={["doctor", "staff"]}
                component={UpdatePrescription}
              />
            }
          />
          <Route
            path="/dashboard/viewAppointmentbyDoctor/view/prescription/:appointmentId"
            element={
              <ProtectedRoute
                roles={["doctor", "staff"]}
                component={ViewPrescription}
              />
            }
          />

          {/* STAFF */}

          {/* future usecase */}
          {/* <Route
            path="/dashboard/view_appointment/datetime"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={ViewAppointmentDateTime}
              />
            }
          />
          <Route
            path="/dashboard/add/appointment_datetime"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={AddAppointmentTime}
              />
            }
          />
          <Route
            path="/dashboard/update/appointment_datetime/:dateTimeId"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={UpdateAppointmentDateTime}
              />
            }
          /> */}

          <Route
            path="/dashboard/printout/consultation/:appointmentId"
            element={
              <ProtectedRoute
                roles={["admin", "staff", "doctor"]}
                component={ConsultationPrintout}
              />
            }
          />

          <Route
            path="/dashboard/printout/history/:appointmentId"
            element={
              <ProtectedRoute
                roles={["admin", "staff", "doctor"]}
                component={HistoryPrintout}
              />
            }
          />

          <Route
            path="/dashboard/addstaff"
            element={<ProtectedRoute roles={["admin"]} component={AddStaff} />}
          />
          <Route
            path="/dashboard/update/staff/:staffId"
            element={
              <ProtectedRoute roles={["admin"]} component={UpdateStaff} />
            }
          />
          <Route
            path="/dashboard/viewstaff"
            element={<ProtectedRoute roles={["admin"]} component={ViewStaff} />}
          />

          {/* Appointment for staff */}
          <Route
            path="/dashboard/viewappointment"
            element={
              <ProtectedRoute
                roles={["admin", "staff", "labreporter"]}
                component={ViewAppointment}
              />
            }
          />

          {/* view pending appointments */}
          <Route
            path="/dashboard/pending/user/appointments"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={ViewAppointmentRequestFromPatient}
              />
            }
          />
          <Route
            path="/dashboard/update/pending/user/appointments/:appointmentId"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={UpdateAppointmentRequestByStaff}
              />
            }
          />

          <Route
            path="/dashboard/add/new/appointment"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={AddNewAppointmentStaff}
              />
            }
          />
          <Route
            path="/dashboard/update/new/appointment/:appointmentId"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={UpdateNewAppointmentStaff}
              />
            }
          />
          <Route
            path="/dashboard/view/inprogress/appointment"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={ViewInProgressAppointment}
              />
            }
          />
          <Route
            path="/dashboard/view/referred/appointment"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={ViewStaffReferredAppointment}
              />
            }
          />
          <Route
            path="/dashboard/view/cancelstatus/appointment"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={ViewCancelStatusAppointment}
              />
            }
          />
          <Route
            path="/dashboard/view/completed/appointment"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={ViewCompletedAppointment}
              />
            }
          />

          <Route
            path="/dashboard/staff/refer_department/:appointmentId"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={ReferredDepartmentByStaff}
              />
            }
          />
          <Route
            path="/dashboard/staff/change/refer_department/:appointmentId"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={ChangeReferredDepartmentByStaff}
              />
            }
          />
          <Route
            path="/dashboard/staff/update/datetime/appointment/:appointmentId"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={UpdateReferredAndFollowUpAppointmentDateTime}
              />
            }
          />
          <Route
            path="/dashboard/staff/add/followup/datetime/appointment/:appointmentId"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={AddFollowUpDateTime}
              />
            }
          />

          {/* Generate lab bills by staff */}
          <Route
            path="/dashboard/get/generated/bills"
            element={
              <ProtectedRoute roles={["staff"]} component={ViewLabBillsPage} />
            }
          />

          <Route
            path="/dashboard/get/detailedbill/:billId"
            element={
              <ProtectedRoute roles={["staff"]} component={ViewDetailedBill} />
            }
          />
          <Route
            path="/dashboard/add/testbill"
            element={
              <ProtectedRoute roles={["staff"]} component={CreateTestBill} />
            }
          />
          <Route
            path="/dashboard/update/testbill/:billId"
            element={
              <ProtectedRoute roles={["staff"]} component={UpdateTestBill} />
            }
          />
          <Route
            path="/dashboard/print/test_invoice/:billId"
            element={
              <ProtectedRoute
                roles={["staff"]}
                component={StaffBillPrintInvoice}
              />
            }
          />

          {/* Appointment History */}
          <Route
            path="/dashboard/appointments/history"
            element={
              <ProtectedRoute
                roles={["staff", "doctor"]}
                component={ViewAppointmentHistory}
              />
            }
          />

          {/* PHARMA STAFF */}
          <Route
            path="/dashboard/addpharmastaff"
            element={
              <ProtectedRoute roles={["admin"]} component={AddPharmaStaff} />
            }
          />
          <Route
            path="/dashboard/update/pharmastaff/:userId"
            element={
              <ProtectedRoute roles={["admin"]} component={UpdatePharmaStaff} />
            }
          />
          <Route
            path="/dashboard/viewpharmastaff"
            element={
              <ProtectedRoute roles={["admin"]} component={ViewPharmaStaff} />
            }
          />

          {/* Lab Reporter */}
          <Route
            path="/dashboard/addlabreporter"
            element={
              <ProtectedRoute roles={["admin"]} component={AddLabReporter} />
            }
          />
          <Route
            path="/dashboard/update/labreporter/:userId"
            element={
              <ProtectedRoute roles={["admin"]} component={UpdateLabReporter} />
            }
          />
          <Route
            path="/dashboard/viewlabreporter"
            element={
              <ProtectedRoute roles={["admin"]} component={ViewLabReporter} />
            }
          />
          {/* <Route
            path="/dashboard/add_report/:appointmentId"
            element={
              <ProtectedRoute
                roles={["labreporter"]}
                component={AddLabReport}
              />
            }
          /> */}
          <Route
            path="/dashboard/add-report/:appointmentId"
            element={
              <ProtectedRoute
                roles={["labreporter"]}
                component={AddReport}
              />
            }
          />
          <Route
            path="/dashboard/add-self-report"
            element={
              <ProtectedRoute
                roles={["labreporter"]}
                component={AddSelfReport}
              />
            }
          />
          <Route
            path="/dashboard/update-report/:reportId"
            element={
              <ProtectedRoute
                roles={["labreporter"]}
                component={UpdateReport}
              />
            }
          />
          <Route
            path="/dashboard/view/labreports"
            element={
              <ProtectedRoute
                roles={["labreporter"]}
                component={ViewLabReports}
              />
            }
          />
          x
          <Route
            path="/dashboard/view/labselfreports"
            element={
              <ProtectedRoute
                roles={["labreporter"]}
                component={ViewLabSelfReports}
              />
            }
          />
          <Route
            path="/dashboard/update/report/:reportId"
            element={
              <ProtectedRoute
                roles={["labreporter"]}
                component={UpdateLabReport}
              />
            }
          />
          <Route
            path="/dashboard/printout/report/:reportId"
            element={
              <ProtectedRoute
                roles={["labreporter"]}
                component={ReportPrintout}
              />
            }
          />

          <Route
            path="/dashboard/printout/selfreport/:reportId"
            element={
              <ProtectedRoute
                roles={["labreporter"]}
                component={SelfReportPrintout}
              />
            }
          />

          {/* Patient */}
          <Route
            path="/dashboard/viewuser"
            element={
              <ProtectedRoute roles={["admin", "staff"]} component={User} />
            }
          />
          <Route
            path="/dashboard/patientadd"
            element={
              <ProtectedRoute roles={["staff"]} component={AddPatient} />
            }
          />
          <Route
            path="/dashboard/update/patient/:patientId"
            element={
              <ProtectedRoute roles={["staff"]} component={UpdatePatient} />
            }
          />

          {/* Symptoms */}
          <Route
            path="/dashboard/symptoms"
            element={
              <ProtectedRoute roles={["admin"]} component={ViewSymptoms} />
            }
          />
          <Route
            path="/dashboard/add_symptom"
            element={
              <ProtectedRoute roles={["admin"]} component={AddSymptoms} />
            }
          />
          <Route
            path="/dashboard/update/symptom/:id"
            element={
              <ProtectedRoute roles={["admin"]} component={UpdateSymptom} />
            }
          />

          {/* Doctor */}
          <Route
            path="/dashboard/doctoradd"
            element={<ProtectedRoute roles={["admin"]} component={AddDoctor} />}
          />
          <Route
            path="/dashboard/update/doctor/:doctorId"
            element={
              <ProtectedRoute roles={["admin"]} component={UpdateDoctor} />
            }
          />
          <Route
            path="/dashboard/viewdoctor"
            element={<ProtectedRoute roles={["admin"]} component={Doctor} />}
          />

          {/* DEPARTMENT */}
          <Route
            path="/dashboard/department"
            element={
              <ProtectedRoute roles={["admin"]} component={Department} />
            }
          />
          <Route
            path="/dashboard/department/create"
            element={
              <ProtectedRoute roles={["admin"]} component={CreateDepartment} />
            }
          />
          <Route
            path="/dashboard/department/update/:departmentId"
            element={
              <ProtectedRoute roles={["admin"]} component={UpdateDepartment} />
            }
          />

          {/* Refers */}
          <Route
            path="/dashboard/refer"
            element={
              <ProtectedRoute roles={["staff"]} component={CheckRefers} />
            }
          />
          {/* <Route path="/dashboard/refer/create" element={<AddPartner />} /> */}
          <Route
            path="/dashboard/refer/update/:appointment_id"
            element={
              <ProtectedRoute roles={["staff"]} component={UpdateRefer} />
            }
          />

          {/* Medicine */}
          <Route
            path="/dashboard/medicine"
            element={<ProtectedRoute roles={["pharma"]} component={Medicine} />}
          />
          {/* <Route path="/dashboard/medicine/billing" element={<Medicine />} /> */}
          <Route
            path="/dashboard/medicine/create"
            element={
              <ProtectedRoute roles={["pharma"]} component={AddMedicine} />
            }
          />

          {/* by admin */}
          <Route
            path="/dashboard/view/medicine"
            element={
              <ProtectedRoute
                roles={["admin"]}
                component={ViewDoctorMedicineByAdmin}
              />
            }
          />
          <Route
            path="/dashboard/add/medicine"
            element={
              <ProtectedRoute
                roles={["admin"]}
                component={AddDoctorMedicineAdmin}
              />
            }
          />
          {/* by admin */}
          <Route
            path="/dashboard/update/medicine/:medId"
            element={
              <ProtectedRoute
                roles={["admin"]}
                component={UpdateDoctorMedicineAdmin}
              />
            }
          />

          {/* by pharma different routes */}
          <Route
            path="/dashboard/medicine/update/:medId"
            element={
              <ProtectedRoute roles={["pharma"]} component={UpdateMedicine} />
            }
          />
          <Route
            path="/dashboard/bills"
            element={
              <ProtectedRoute roles={["pharma"]} component={BillsPage} />
            }
          />
          <Route
            path="/dashboard/generate/bill"
            element={<ProtectedRoute roles={["pharma"]} component={AddBill} />}
          />
          <Route
            path="/dashboard/update/bill/:billID"
            element={
              <ProtectedRoute roles={["pharma"]} component={UpdateBill} />
            }
          />
          <Route
            path="/dashboard/pharmacy/print-invoice/:billID"
            element={
              <ProtectedRoute roles={["pharma"]} component={PrintInvoice} />
            }
          />

          {/* Help and Support */}
          {/* Settings */}
          <Route
            path="/dashboard/changepassword"
            element={
              <ProtectedRoute
                roles={["admin", "doctor", "staff", "labreporter", "pharma"]}
                component={ChangePassword}
              />
            }
          />
          <Route
            path="/dashboard/profile"
            element={
              <ProtectedRoute
                roles={["doctor", "staff", "labreporter", "pharma"]}
                component={UserProfile}
              />
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/pagenotfound" />} />
      </Routes>
    </>
  );
};
export default Mid;
