import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, message, Row, Col, Select } from "antd";
import { AiFillMinusCircle } from "react-icons/ai";

import LoadingScreen from "../lib/constants/loadingScreen";
import { useParams } from "react-router-dom";

const UpdateDoctorMedicineAdmin = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { medId } = useParams();
  const [form] = Form.useForm();
  const [actionLoading, setActionLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // State for dynamic fields
  const [field1Items, setField1Items] = useState(['']);
  const [field2Items, setField2Items] = useState(['']);
  const [field3Items, setField3Items] = useState(['']);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/admin/get/medicine/${medId}`, config)
      .then((response) => {
        if (response.data.success) {
          const medicine = response.data.data;
          form.setFieldsValue({
            name: medicine.name,
            type: medicine.type,
            affiliateName: medicine.affiliateName,
          });

          // Set dynamic fields if they exist
          if (medicine.dose) {
            setField1Items(medicine.dose.length ? medicine.dose : ['']);
          }
          if (medicine.warning) {
            setField2Items(medicine.warning.length ? medicine.warning : ['']);
          }
          if (medicine.sideEffect) {
            setField3Items(medicine.sideEffect.length ? medicine.sideEffect : ['']);
          }
        } else {
          const errorMessage =
            response.data.error ||
            "Failed to fetch medicine details! Please try again";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching medicine details", error);
        const errorMessage =
          error.response?.data?.error ||
          "Failed to fetch medicine details! Please try again";
        message.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddField = (fieldNumber) => {
    switch (fieldNumber) {
      case 1:
        setField1Items([...field1Items, '']);
        break;
      case 2:
        setField2Items([...field2Items, '']);
        break;
      case 3:
        setField3Items([...field3Items, '']);
        break;
      default:
        break;
    }
  };

  const handleRemoveField = (index, fieldNumber) => {
    switch (fieldNumber) {
      case 1:
        setField1Items(field1Items.filter((_, i) => i !== index));
        break;
      case 2:
        setField2Items(field2Items.filter((_, i) => i !== index));
        break;
      case 3:
        setField3Items(field3Items.filter((_, i) => i !== index));
        break;
      default:
        break;
    }
  };

  const handleFieldChange = (index, value, fieldNumber) => {
    switch (fieldNumber) {
      case 1:
        const newField1Items = [...field1Items];
        newField1Items[index] = value;
        // Remove empty fields except the last one
        if (newField1Items.length > 1 && !value) {
          newField1Items.splice(index, 1);
        }
        setField1Items(newField1Items);
        break;
      case 2:
        const newField2Items = [...field2Items];
        newField2Items[index] = value;
        if (newField2Items.length > 1 && !value) {
          newField2Items.splice(index, 1);
        }
        setField2Items(newField2Items);
        break;
      case 3:
        const newField3Items = [...field3Items];
        newField3Items[index] = value;
        if (newField3Items.length > 1 && !value) {
          newField3Items.splice(index, 1);
        }
        setField3Items(newField3Items);
        break;
      default:
        break;
    }
  };

  const updateMedicine = async (values) => {
    setActionLoading(true);

    const data = {
      name: values.name,
      type: values.type,
      affiliateName: values.affiliateName,
      dose: field1Items.filter(item => item.trim()).join(',') || '',
      warning: field2Items.filter(item => item.trim()).join(',') || '',
      sideEffect: field3Items.filter(item => item.trim()).join(',') || ''
    };

    try {
      const response = await axios.put(
        `${apiBaseUrl}/admin/medicine/update/${medId}`,
        data,
        config
      );

      if (response.data.success) {
        message.success(response.data.msg, 0.6, function onClose() {
          window.location.replace("/dashboard/view/medicine");
        });
      } else {
        const errorMessage =
          response.data.error || "Failed to update medicine! Please try again.";
        message.error(errorMessage);
      }
    } catch (error) {
      console.error("Failed to update medicine:", error);
      const errorMessage =
        error?.response?.data?.error ||
        "Failed to update medicine! Please try again.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };

  const renderFieldColumn = (items, fieldNumber) => (
    <div className="flex flex-col space-y-2">
      {items.map((item, index) => (
        <div key={index} className="flex items-center space-x-2">
          <Input
            value={item}
            onChange={(e) => handleFieldChange(index, e.target.value, fieldNumber)}
            placeholder="Enter value"
            className="flex-1"
          />
          {items.length > 1 && (
            <button
              type="button"
              onClick={() => handleRemoveField(index, fieldNumber)}
              className="p-1 text-red-500 hover:text-red-700 rounded-full hover:bg-red-50"
            >
              <AiFillMinusCircle />
            </button>
          )}
        </div>
      ))}
      <button
        type="button"
        onClick={() => handleAddField(fieldNumber)}
        className="text-sky-600 border border-sky-600 rounded-lg py-2 px-4 hover:bg-sky-50"
      >
        Add Field
      </button>
    </div>
  );

  return (
    <div>
      {(actionLoading || loading) && <LoadingScreen />}

      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={updateMedicine}
          className="forbox w-full bg-white max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
        >
          <div className="text-gray-600 font-medium text-3xl mb-4">
            Update Medicine
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Medicine Name"
                name="name"
                rules={[
                  { required: true, message: "Please input medicine name!" },
                ]}
              >
                <Input placeholder="Medicine name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Affiliate Name"
                name="affiliateName"
                rules={[
                  { required: true, message: "Please input Affiliate name!" },
                ]}
              >
                <Input placeholder="Affiliate name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Medicine Type"
                name="type"
                rules={[
                  { required: true, message: "Please select medicine type!" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select or search medicine type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {/* Medicines */}
                  <Select.Option value="Tablets">Tablets</Select.Option>
                  <Select.Option value="Capsules">Capsules</Select.Option>
                  <Select.Option value="Syrup">Syrup</Select.Option>
                  <Select.Option value="Injection">Injection</Select.Option>
                  <Select.Option value="Ointment">Ointment</Select.Option>
                  <Select.Option value="Cream">Cream</Select.Option>
                  <Select.Option value="Gel">Gel</Select.Option>
                  <Select.Option value="Lotion">Lotion</Select.Option>
                  <Select.Option value="Drops">Drops</Select.Option>
                  <Select.Option value="Spray">Spray</Select.Option>
                  <Select.Option value="Inhaler">Inhaler</Select.Option>
                  <Select.Option value="Suppository">Suppository</Select.Option>
                  <Select.Option value="Powder">Powder</Select.Option>
                  <Select.Option value="Suspension">Suspension</Select.Option>
                  <Select.Option value="Solution">Solution</Select.Option>
                  <Select.Option value="Lozenges">Lozenges</Select.Option>
                  <Select.Option value="Chewables">Chewables</Select.Option>
                  <Select.Option value="Implants">Implants</Select.Option>
                  <Select.Option value="Patches">Patches</Select.Option>

                  {/* Medical Devices */}
                  <Select.Option value="Thermometer">Thermometer</Select.Option>
                  <Select.Option value="Blood Pressure Monitor">Blood Pressure Monitor</Select.Option>
                  <Select.Option value="Glucose Meter">Glucose Meter</Select.Option>
                  <Select.Option value="Nebulizer">Nebulizer</Select.Option>
                  <Select.Option value="Oxygen Concentrator">Oxygen Concentrator</Select.Option>
                  <Select.Option value="Pulse Oximeter">Pulse Oximeter</Select.Option>
                  <Select.Option value="Stethoscope">Stethoscope</Select.Option>

                  {/* Surgical Supplies */}
                  <Select.Option value="Bandages">Bandages</Select.Option>
                  <Select.Option value="Gauze">Gauze</Select.Option>
                  <Select.Option value="Splint">Splint</Select.Option>
                  <Select.Option value="Suture Kit">Suture Kit</Select.Option>

                  {/* Diagnostic Kits */}
                  <Select.Option value="Pregnancy Test Kit">Pregnancy Test Kit</Select.Option>
                  <Select.Option value="COVID-19 Test Kit">COVID-19 Test Kit</Select.Option>
                  <Select.Option value="Urine Test Strips">Urine Test Strips</Select.Option>

                  {/* Herbal/Traditional */}
                  <Select.Option value="Ayurvedic Medicine">Ayurvedic Medicine</Select.Option>
                  <Select.Option value="Homeopathic">Homeopathic</Select.Option>
                  <Select.Option value="Herbal Supplements">Herbal Supplements</Select.Option>

                  {/* Nutritional */}
                  <Select.Option value="Vitamins">Vitamins</Select.Option>
                  <Select.Option value="Minerals">Minerals</Select.Option>
                  <Select.Option value="Protein Supplements">Protein Supplements</Select.Option>

                  {/* Specialized */}
                  <Select.Option value="Chemotherapy Drugs">Chemotherapy Drugs</Select.Option>
                  <Select.Option value="Radioactive Medicine">Radioactive Medicine</Select.Option>
                  <Select.Option value="Vaccines">Vaccines</Select.Option>

                  {/* Miscellaneous */}
                  <Select.Option value="Disinfectant">Disinfectant</Select.Option>
                  <Select.Option value="Sanitizer">Sanitizer</Select.Option>
                  <Select.Option value="First Aid Kit">First Aid Kit</Select.Option>
                  <Select.Option value="Surgical Mask">Surgical Mask</Select.Option>
                  <Select.Option value="Orthopedic Support">Orthopedic Support</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Dynamic fields table */}
          <div className="mt-6">
            <div className="grid grid-cols-3 gap-4">
              <div>
                <div className="font-medium mb-2">Dose</div>
                {renderFieldColumn(field1Items, 1)}
              </div>
              <div>
                <div className="font-medium mb-2">Warning</div>
                {renderFieldColumn(field2Items, 2)}
              </div>
              <div>
                <div className="font-medium mb-2">Side Effects</div>
                {renderFieldColumn(field3Items, 3)}
              </div>
            </div>
          </div>

          <Form.Item className="mt-6">
            <button
              id="addStaffBtn"
              className="w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
              type="submit"
            >
              Submit
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UpdateDoctorMedicineAdmin;