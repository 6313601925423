import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingScreen from "../lib/constants/loadingScreen";
import { Form, Input, Select, message, Row, Col, Typography } from "antd";
import { useParams, Link } from "react-router-dom";
import { calculateAge } from "../lib/constants/calculateAge";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import "./placeholder.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

const { Option } = Select;
const { Title } = Typography;

const UpdatePatient = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { patientId } = useParams();
  const [form] = Form.useForm();
  const [DOB, setDOBinAD] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [isDOBMatched, setIsDOBMatched] = useState(false);
  const [dateBS, setDateBS] = useState("");
  const [dateAD, setDateAD] = useState("");
  const [defaultDateBS, setDefaultDateBS] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);

  let calculatedAge;

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    calculatedAge = calculateAge(DOB);

    if (
      calculatedAge !== undefined &&
      calculatedAge !== "Invalid date of birth"
    ) {
      // form.setFieldsValue({ age: calculatedAge });

      if (dateAD === DOB) {
        setIsDOBMatched(true);
      } else {
        setIsDOBMatched(false);
      }
    }
    // else {
    //   form.setFieldsValue({ age: null });
    // }
  }, [DOB, dateAD]);

  const dateBStoAD = () => {
    if (dateAD) {
      setDOBinAD(dateAD);
    } else {
      form.setFieldsValue({ age: null });
    }
  };

  const handleDateInBS = ({ bsDate, adDate }) => {
    setDateBS(bsDate);
    setDateAD(adDate);
    setDefaultDateBS(bsDate);
  };

  const handleClear = () => {
    setDefaultDateBS(null);
    setDateBS(null);
  };

  // Function to calculate DOB based on age
  const handleAgeChange = (e) => {
    const inputAge = e.target.value;

    try {
      if (inputAge) {
        const today = new Date();
        const birthYear = today.getFullYear() - inputAge;
        const birthDate = new Date(
          birthYear,
          today.getMonth(),
          today.getDate()
        );
        const calculatedDOB = birthDate.toISOString().split("T")[0];
        setDOBinAD(calculatedDOB);
      } else {
        setDOBinAD("");
      }
    } catch (error) {
      message.error("Please enter valid age.");
    }
  };

  useEffect(() => {
    fetchPatient();
  }, []);

  const fetchPatient = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/patient/${patientId}`, config)
      .then((response) => {
        if (response.data.success) {
          const patient = response.data.data;
          const fetchDOB = response.data.data.DOB;
          const extractedDOB = fetchDOB?.split("T")[0];

          form.setFieldsValue({
            fullname: patient.fullname,
            phone: patient.phone,
            address: patient.address,
            gender: patient.gender,
            // age: patient.age,
          });
          setDOBinAD(extractedDOB);
          setIsDataFetched(true);
        } else {
          const errorMessage =
            response.data.error ||
            "An error occurred while fetching patient details.";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient", error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An unexpected error occurred while fetching patient details.";
        message.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePatient = async (values) => {
    setActionLoading(true);

    const data = {
      fullname: values.fullname,
      phone: values.phone,
      address: values.address,
      gender: values.gender,
      DOB,
    };

    try {
      const response = await axios.put(
        `${apiBaseUrl}/patient/update/${patientId}`,
        data,
        config
      );
      if (response.data.success) {
        message.success(
          "Patient updated successfully",
          0.6,
          function onClose() {
            window.location.replace("/dashboard/viewuser");
          }
        );
      } else {
        const errorMessage =
          response.data.error ||
          "An error occurred while updating the patient.";
        message.error(errorMessage);
      }
    } catch (err) {
      console.error("Failed to update patient:", err);
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        "An unexpected error occurred while updating the patient.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div>
      {(actionLoading || loading) && <LoadingScreen />}

      <Form
        form={form}
        onFinish={updatePatient}
        layout="vertical"
        className="forbox w-full max-w-3xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
      >
        <Title level={2}>Update Patient</Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Fullname"
              name="fullname"
              rules={[{ required: true, message: "Please enter full name" }]}
            >
              <Input placeholder="Enter full name" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="DOB (in BS)"
              name="dateInAD"
              rules={[{ required: false, message: "Please select Date" }]}
            >
              {isDataFetched && (
                <>
                  <Calendar
                    key={defaultDateBS}
                    onChange={handleDateInBS}
                    theme="blue"
                    defaultDate={defaultDateBS}
                    hideDefaultValue={!defaultDateBS}
                    language="en"
                    placeholder="Select Date(BS)"
                    className="border border-gray-300 border py-1.5 px-3 rounded-md text-gray-800 custom-placeholder w-full"
                  />
                  {dateBS && (
                    <AiOutlineCloseCircle
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
                      onClick={handleClear}
                    />
                  )}
                </>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: "Please enter phone number" }]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="DOB (in AD)"
              rules={[
                {
                  message: "Please enter a valid email address",
                },
                { required: true, message: "Please enter email address" },
              ]}
            >
              <input
                id="DOB"
                value={DOB}
                type="date"
                onChange={(e) => {
                  setDOBinAD(e.target.value);
                }}
                max={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3 date-picker"
                name="dob"
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Age(Years)"
              name="age"
              onChange={handleAgeChange}
              rules={[{ required: false, message: "Please enter age" }]}
            >
              <Input placeholder="Age" type="number" />
            </Form.Item>
          </Col>
        </Row>
        {!isDOBMatched && dateBS && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="-mt-5 mb-3 flex justify-end">
                <p className="text-red-500 italic me-2">
                  The selected DOB(in BS) does not match the DOB(in AD).
                </p>
                <Link
                  onClick={dateBStoAD}
                  className="text-blue-500 italic border-b hover:text-blue-600 font-semibold inline-flex items-center"
                >
                  Click here to correct it.
                </Link>
              </div>
            </Col>
          </Row>
        )}
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Please enter address" }]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select gender" }]}
            >
              <Select placeholder="Select gender">
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default UpdatePatient;
