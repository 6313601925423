import React, { useState, useEffect } from "react";
import { Button, Divider, message } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { calculateAge } from "../../lib/constants/calculateAge";

const ReportPrintout = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const { reportId } = useParams();
    const [DOB, setDOB] = useState("");
    const [age, setAge] = useState("");
    const [loading, setLoading] = useState("");
    const [details, setDetails] = useState("");
    let calculatedAge;

    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
        },
    };

    useEffect(() => {
        calculatedAge = calculateAge(DOB);
        if (calculatedAge !== undefined && calculatedAge !== "Invalid date of birth") {
            setAge(calculatedAge);
        } else {
            setAge("");
        }
    }, [DOB]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const url = `${apiBaseUrl}/view/single/report/${reportId}`;

        await axios
            .get(url, config)
            .then((response) => {
                if (response.data.success) {
                    const data = response.data.data;
                    setDetails(data);
                    setDOB(data.patientId.DOB);
                }
            })
            .catch((error) => {
                console.error("Error fetching appointments:", error);
                message.error("Failed to get appointment details! Please try again.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handlePrint = () => {
        const printableContent = document.getElementById("printableContent").innerHTML;
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = printableContent;
        document.body.innerHTML = tempDiv.innerHTML;
        window.print();
        window.location.replace("/dashboard/view/labreports");
    };

    const convertToNepaliTime = (utcTime) => {
        return moment.utc(utcTime).tz("Asia/Kathmandu").format("YYYY-MM-DD h:mm A");
    };

    const renderReportTable = () => {
        if (!details?.report || details.report.length === 0) return null;

        return (
            <div className="mt-6">
                <table className="w-full border-collapse compact-table">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border px-2 text-left"> Test Name</th>
                            <th className="border px-2 text-left"> Result</th>
                            <th className="border px-2 text-left"> Unit</th>
                            <th className="border px-2 text-left"> Reference Range</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.report.map((item, index) => (
                            <tr key={item._id}>
                                <td className=" px-2 border">
                                    {(!item.result && !item.unit && !item.refRange) ? (
                                        <span className="font-bold">{item.test}</span>
                                    ) : (
                                        item.test
                                    )}
                                </td>
                                <td className=" px-2 border">{item.result || '-'}</td>
                                <td className=" px-2 border">{item.unit || '-'}</td>
                                <td className=" px-2 border">{item.refRange || '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div>
            <div id="printableContent" className="print-content">
                <div className="container p-4 place-content-center">
                    <div className="mb-3">
                        <div className="text-center">
                            <span className="text-2xl">Tulsi MultiSpeciality Clinic</span>
                            <p>
                                <EnvironmentOutlined /> Avm chowk 05, Lalitpur 44600
                            </p>
                            <p>
                                <PhoneOutlined /> 01-5914294
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-between mb-8">
                        <div className="flex flex-col text-start">
                            <p>Name: {details?.patientId?.fullname}</p>
                            <p>
                                Age/ Sex: {age}
                                {"/"} {details?.patientId?.gender}
                            </p>
                            <p>Address: {details?.appointmentId?.address}</p>
                        </div>
                        <div className="flex flex-col text-end">
                            <p>Appointment No: {details?.appointmentId?.appointmentNumber}</p>
                            <p>UserID: {details?.patientId?.userID}</p>
                            <p>
                                Report Date: {convertToNepaliTime(details?.createdAt)}
                            </p>
                        </div>
                    </div>

                    <Divider />
                    {/* New Report Name Section */}
                    {details?.reportName && (
                        <div className="mb-4">
                            <h1 className="text-xl font-bold">
                                {details.reportName} Report
                            </h1>
                        </div>
                    )}

                    {renderReportTable()}


                    <div className="flex justify-center items-center my-8">
                        <h1 className="text-m font-medium py-10">
                            PAWAN SHRESTHA<br />
                            LAB TECHNOLOGIST<br />
                            NHPC NO: A-1675 MLT
                        </h1>
                    </div>



                    <div className="flex justify-center items-center my-8">
                        <h1 className="text-m font-medium">
                            ------------End of Report-------------

                        </h1>
                    </div>


                    <div className="generated-by text-end me-5 mt-3">
                        {details?.doctorId && (
                            <p className="mt-8">
                                Dr. {details.doctorId.fullname}<br />
                                {details.appointmentId?.department?.department}
                            </p>
                        )}
                    </div>
                </div>
            </div>

            <Button
                type="primary"
                onClick={handlePrint}
                className="mt-4 bg-sky-600 hover:bg-sky-700 text-white border shadow font-semibold text-md rounded-md"
            >
                Print Report
            </Button>
        </div>
    );
};

export default ReportPrintout;