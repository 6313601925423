import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Form, Input, Row, Col, message, Button, Modal } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";

const { TextArea } = Input;

const ViewAdvice = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();
  // const navigate = useNavigate();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const location = useLocation();
  const { full_name, problem, appointmentNumber, appointmentHistory } = location.state || {};
  const [fullNameData, setFullNameData] = useState("");
  const [problemData, setProblemData] = useState("");
  const [appointmentNumberData, setAppointmentNumberData] = useState("");

  const [hasEditableAdvice, setHasEditableAdvice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [currentAdviceId, setCurrentAdviceId] = useState(null);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };
  // const doctorConfig = {
  //   headers: {
  //     Authorization: "Bearer " + localStorage.getItem("doctorticket"),
  //   },
  // };

  // const staffConfig = {
  //   headers: {
  //     Authorization: "Bearer " + localStorage.getItem("staffticket"),
  //   },
  // };

  useEffect(() => {
    setFullNameData(full_name);
    setProblemData(problem);
    setAppointmentNumberData(appointmentNumber);
    fetchData();


  }, []);

  useEffect(() => {
    const checkEditableAdvice = () => {
      if (!details || details.length === 0) return false;

      return details.some(item => {
        if (!item.appointmentId?.appointmentHistory) return false;

        const lastHistory = item.appointmentId.appointmentHistory[
          item.appointmentId.appointmentHistory.length - 1
        ];

        return lastHistory?.advice?.some(
          prescriptionItem => prescriptionItem._id === item._id
        );
      });
    };

    setHasEditableAdvice(checkEditableAdvice());
  }, [details]);

  const fetchData = () => {
    axios
      .get(`${apiBaseUrl}/advice/${appointmentId}`)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          console.log("Advice Data", response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching advice:", error);
      });
  };

  const showModal = () => {

    setIsModalOpen(true);
  };

  const showUpdateModal = (advice, adviceId) => {
    updateForm.setFieldsValue({
      advice: advice,
    });
    setCurrentAdviceId(adviceId);
    setIsUpdateModalOpen(true);
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        addAdvice(values);
        form.resetFields();
        setIsModalOpen(false);
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  const handleUpdateOk = () => {
    // the value of arg is Advice ID

    updateForm
      .validateFields()
      .then((values) => {
        // console.log("Updated Advice:", { currentAdviceId, ...values });
        updateAdvice(values);
        updateForm.resetFields();
        setIsUpdateModalOpen(false);
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  const handleCancel = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        title: "Are you sure you want to discard changes?",
        content: "Your changes will not be saved.",
        okText: "Yes, discard",
        cancelText: "No, keep editing",
        onOk: () => {
          form.resetFields();
          setIsModalOpen(false);
        },
      });
    } else {
      setIsModalOpen(false);
    }
  };

  const handleUpdateCancel = () => {
    if (updateForm.isFieldsTouched()) {
      Modal.confirm({
        title: "Are you sure you want to discard changes?",
        content: "Your changes will not be saved.",
        okText: "Yes, discard",
        cancelText: "No, keep editing",
        onOk: () => {
          updateForm.resetFields();
          setIsUpdateModalOpen(false);
        },
      });
    } else {
      setIsUpdateModalOpen(false);
    }
  };

  const updateAdvice = async (values) => {
    setActionLoading(true);
    // console.log("Logging in update advice ", values, id);
    const data = {
      advice: values.advice,
    };
    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.put(
          `${apiBaseUrl}/doctor/advice/${currentAdviceId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.put(
          `${apiBaseUrl}/staff/advice/${currentAdviceId}`,
          data,
          config
        );
      }

      if (response.data.success) {
        message.success(response.data.message, 0.6, function onClose() {
          // fetchData();
          window.location.reload();
        });
      } else {
        message.error(
          response?.data?.error?.error || "Failed to update advice."
        );
      }
    } catch (error) {
      console.error("Failed to update advice:", error);
      message.error("Failed to update advice! Please try again.");
    } finally {
      setActionLoading(false);
    }
  }

  const addAdvice = async (values) => {
    setActionLoading(true);

    const data = {
      advice: values.advice,
    };

    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.post(
          `${apiBaseUrl}/doctor/advice/${appointmentId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.post(
          `${apiBaseUrl}/staff/advice/${appointmentId}`,
          data,
          config
        );
      }

      if (response.data.success) {
        message.success(response.data.message, 0.6, function onClose() {
          // window.location.reload();
          fetchData();
        });
      } else {
        message.error(
          response?.data?.error?.error || "Failed to add advice."
        );
      }
    } catch (error) {
      console.error("Failed to add advice:", error);
      message.error("Failed to add advice! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const deleteAdvice = async (advice_id) => {
    try {
      setActionLoading(true);
      if (window.confirm("Are you sure want to delete this advice?")) {
        let response;
        if (localStorage.getItem("doctorticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/doctor/advice/${advice_id}`,
            config
          );
        } else if (localStorage.getItem("staffticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/staff/advice/${advice_id}`,
            config
          );
        }
        if (response.data.success) {
          message.success(response.data.message
            // , 0.6, function onClose() {
            // window.location.reload();
            // }
          );
          fetchData();
        } else {
          message.error(
            response?.error?.message || "Failed to delete the advice."
          );
        }
      }
    } catch (e) {
      console.error("Failed to delete advice:", e);
      message.error("Failed to delete! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div className="mb-3">
        <h1 className="text-3xl font-bold decoration-gray-400">Advice</h1>
      </div>

      <div className="flex items-start">
        <div className="max-w-3xl p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow">
          {loading && <LoadingScreen />}

          {!loading && (
            <>
              <div className="text-center">
                <h5 className=" text-xl font-bold tracking-tight text-gray-900">
                  Appointment Number: {" "}
                  <span className="font-semibold italic">{` ${appointmentNumberData}`}</span>
                </h5>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                  Patient Name:{" "}
                  <span className="font-semibold italic">{` ${fullNameData}`}</span>
                </h5>

                <p className="mb-1 font-semibold text-gray-700">
                  Problem: <span className="font-normal">{` ${problemData}`}</span>
                </p>
              </div>
              <hr class="w-48 h-1 mx-auto bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700"></hr>

              {details.length > 0 ? (
                details.map((item) => {
                  return (
                    <div
                      key={item._id}
                      className="relative mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow flex"
                    >
                      {/* Left Content */}
                      <div className="flex-grow">
                        <p
                          className="mb-1 font-semibold text-gray-700"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          Advice Report:{" "}
                          <span className="font-normal">{item.advice}</span>
                        </p>
                        <p className="mb-1 font-semibold text-gray-700">
                          Added by:{" "}
                          <span className="font-normal">
                            Dr. {item.doctorId.fullname}
                          </span>
                        </p>
                      </div>

                      {/* Dotted Vertical Line */}
                      <div className="relative">
                        <div className="absolute top-0 bottom-0 border-r-2 border-dotted border-gray-400"></div>
                      </div>

                      {/* Buttons Section */}

                      {item.appointmentId.appointmentHistory[item.appointmentId.appointmentHistory.length - 1]?.advice.some(adviceItem =>
                        adviceItem._id === item._id
                      ) && (<div className="flex flex-col items-start pl-6">
                        {/* <Link */}
                        {/* // to={`/dashboard/viewOngoingAppointmentbyDoctor/update_prescription/${item._id}`}
                        // state={{ appointmentId: appointmentId }} */}
                        {/* > */}
                        <div className="ms-2">
                          <button className="px-4 py-1 mb-2 rounded-md bg-gray-600 text-sky-100 hover:bg-gray-700" onClick={() => showUpdateModal(item.advice, item._id)}>
                            Update
                          </button>
                          <Modal
                            title="Update Advice"
                            open={isUpdateModalOpen}
                            onOk={handleUpdateOk}
                            onCancel={handleUpdateCancel}
                          >
                            <Form form={updateForm} onFinish={updateAdvice} layout="vertical">
                              <Row gutter={[16, 16]}>
                                <Col span={24}>
                                  <Form.Item
                                    label="Advice"
                                    name="advice"
                                    rules={[
                                      { required: true, message: "Please enter advice." },
                                    ]}
                                  >
                                    <TextArea rows={4} placeholder="Enter advice..." />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </Modal>
                        </div>
                        {/* </Link> */}
                        <Link
                          onClick={() => {
                            deleteAdvice(item._id);
                          }}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <div className="ms-2">
                            <button className="px-5 py-1 rounded-md bg-red-700 text-sky-100 hover:bg-red-800">
                              Delete
                            </button>
                          </div>
                        </Link>
                      </div>)}
                    </div>
                  );
                })
              ) : (
                <>
                  <p className="mt-3 text-center">
                    <h5 className="mb-1 text-xl text-red-500 font-semibold tracking-tight text-gray-900">
                      No Advice added!!
                    </h5>
                  </p>
                </>
              )}
            </>
          )}
        </div>
        {!hasEditableAdvice && (<div className="ms-2">
          <Button type="primary" onClick={showModal}>
            Add Advice
          </Button>
          <Modal
            title="Add Advice"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form form={form} onFinish={addAdvice} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item
                    label="Advice"
                    name="advice"
                    rules={[
                      { required: true, message: "Please enter advice." },
                    ]}
                  >
                    <TextArea rows={4} placeholder="Enter advice..." />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>)}
      </div>
    </>
  );
};
export default ViewAdvice;
