import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Form, Input, Row, Col, message, Button, Modal, Select, Tag } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";

const { TextArea } = Input;

const ViewSummary = () => {

  const diseaseOptions = {
    RHEUMATOID_ARTHRITIS:
      "Mr/Mrs/Miss/Master {patient_name} {age} years old {gender} from {address} presented with complain of JointPain predominantly\n" +
      "involving small/large - Symmetrical/Asymmetrical Joints of upper/lower extrimities for ___ Duration. In Addition He/She also had easy fatiguability.\n" +
      "And on examination right/left - 1st, 2nd, 3rd, 4th MCP were Tender/Swollen with 1st, 2nd, 3rd, 4th PIP Swollen/Tender and with/without deformities \n" +
      "On investigation rheumatoid factor : ____ , AntiCCP ____, ESR ____, CRP ____.\n\n" +

      "Current Issues : Second Opinion / Symptoms suggestive of increased Disease Activity due to non compliance to medicine and medical advices / inappropriate\n" +
      "diagnosis / inadequate medication / Other Illness - Infection.\n\n" +

      "Current Pertinent Investigation : Raised ESR / CRP / Lucocytosis / Anemia / Thrumbocytopenia / Transaminitis / Dearranged Renal function test."
    ,
    SYSTEMIC_LUPUS_ERYTHEMATOSUS:
      "Initially Diagnosed on the ground of cutaneous menifestation, Hairfall, Oral Ulcer, Arthritis, Arthalgia, Constitutational\n" +
      ", Renal Involvement, Serositis, Myalgia, Myositis, Vasculitis, Hematological(Anemia, Pancytopenia, Leucopenia, Thrumbocytopenia) with serology.\n\n" +

      "ANA by-IFA : Intensity +++, 1:80/1:160/1:320/1:640/1:1280/1:2560/1:5120, \n" +
      "Pattern : Homogeneous (Diffuse) Pattern / Speckled Pattern / Nucleolar Pattern \n" +
      "/ Centromere Pattern / Cytoplasmic Pattern / Peripheral Patttern  / Mitotic Pattern  / Dense Fine Speckled Pattern. \n\n" +

      "Antibody Profile : Anti-dsDNA: +++ / Anti-Sm: +++ / Anti-U1RNP: +++ / Anti-nucleosome: +++ / Anti-histones: +++ / Anti-Sjögren syndrome-A/Ro(anti-SSA/Ro) +++\n" +
      "/ Anti-Sjögren's syndrome antigen-B (anti-SS-B/LA) +++ / Anti-systemic sclerosis-70 (Scl-70) antibody: +++ / Anti-histidyl-tRNA synthetase antibody(Jo-1)+++\n" +
      "/ Anti-RNP, anti-Ku and Ki antibodies +++ / Anti-ribosomal P protein (RPP) antibody +++ / Anti- polymyositis-systemic scleroderma antibody (PM-Scl) +++\n\n" +

      "Initially remission induction was done with cyclophosphamide ____Mg monthly for 6 months and remission maintainence with mycophenolate\n\n" +

      "Conception Plan Yes/No \n\n" +

      "Current Issues : Second Opinion / Symptoms suggestive of increased Disease Activity due to non compliance to medicine and medical advices / inappropriate\n\n" +
      "diagnosis / inadequate medication / Other Illness - Infection.\n\n",
    SPONDYLOARTHRITIS:
      "Initial symptoms: inflammatory low back pain/symptoms suggestive of dactylitis, psoriasis, Inflammatory bowel disease,\n\n" +
      "reactive arthritis/ uveitis __ years/months back.\n\n" +

      "Initial investigation: High/low CRP, HLAB27 +/- \n\n" +

      "X ray SI joint: +/-, MRI Sacroillitis +/-\n\n" +

      "Response to NSAIDs yes/No\n\n" +

      "Family history: positive/negative\n\n" +

      "Initially treated with: sulfasalazine/methotrexate/tofacitinib/ Adalimumab with good/No response\n\n" +

      "Subsequent extra articular involvement : psoriasis, Inflammatory bowel disease/ uveitis __ years/months back. \n\n" +

      "Screening for Diabetes/ dyslipidemia/cardiac comorbidities : done on --- date/ not done/ refused\n\n" +

      "Current Issues : Second Opinion / Symptoms suggestive of increased Disease Activity due to non compliance to medicine and medical advices / inappropriate\n" +
      "diagnosis / inadequate medication / Other Illness - Infection."
    ,
    SYSTEMIC_SCLEROSIS:
      "Initial symptoms suggestive of: skin thickening,  Raynaud’s phenomenon- single, double, triple phase, sclerodactyly,\n" +
      "Digital tip ulcer, digital gangrene, puffiness of hands, stiffness of hands, shortness of breath, palpitations GERD,\n" +
      "Small bowel bacterial overgrowth syndrome, calcinosis ....years back\n" +
      "Subsequent organ involvement: lungs - ILD(UIP / NSIP), Pulmonary artery hypertension - PAP__mmof Hg.Contracture at DIP/PIP/Elbow\n" +
      "Amputation of: Right: 2nd, 3rd, 4th, 5th distal phalanges.Left 2nd, 3rd, 4th, 5th distal phalanges.\n" +
      "Digital tip scar: Right - 2nd, 3rd, 4th, 5th  finger tips\n" +
      "Left - 2nd, 3rd, 4th, 5th  finger tips\n\n" +

      "Antinuclear Antibodies(ANA)\n\n" +

      "ANA by - IFA : Intensity++ +, 1: 80 / 1: 160 / 1: 320 / 1: 640 / 1: 1280 / 1: 2560 / 1: 5120,\n" +
      "Pattern : Homogeneous(Diffuse) Pattern / Speckled Pattern / Nucleolar Pattern\n" +
      "/ Centromere Pattern / Cytoplasmic Pattern / Peripheral Patttern / Mitotic Pattern / Dense Fine Speckled Pattern.\n\n" +

      "Antibody Profile: Anti - dsDNA: +++ / Anti-Sm: +++ / Anti - U1RNP: +++ / Anti-nucleosome: +++ / Anti - histones: +++ / Anti-Sjögren syndrome-A/Ro(anti - SSA / Ro)++ +\n" +
      "/ Anti-Sjögren's syndrome antigen-B (anti-SS-B/LA)+++ / Anti-systemic sclerosis-70 (Scl-70) antibody: +++ / Anti - histidyl - tRNA synthetase antibody(Jo - 1)++ +\n" +
      "/ Anti-RNP, anti-Ku and Ki antibodies +++ / Anti - ribosomal P protein(RPP) antibody++ + / Anti- polymyositis-systemic scleroderma antibody (PM-Scl) +++\n\n" +

      "Specific Autoantibodies\n\n" +

      "Anti - centromere Antibodies(ACA):\n" +
      "Anti - topoisomerase I(Anti - Scl - 70):\n" +
      "Anti - RNA Polymerase III Antibodies:\n" +
      "Anti - U1 RNP Antibodies:\n" +
      "Anti - Th / To Antibodies:\n" +
      "Anti - PM / Scl Antibodies:\n" +
      "Anti - fibrillarin(Anti - U3 RNP):\n" +
      "Anti - Ku Antibodies:\n\n" +

      "Initially treated with: prednisolone, methotrexate, Hydroxychloroquine, mycophenolate, cyclophosphamide __mg for 6 months, Tadalafil 10mg,\n" +
      "Nifedipine 20mg SR, Nintadinib 150mg BD. .... added on Subsequent visit.\n\n" +

      "Current issues: respiratory tract infection / urinary tract infection.Incomplete control of symptoms  related to Raynauds, ILD, GERD, small \n" +
      "bowels Joint pain, skin thickening, contracture, calcinosis, PAH, sideeffect of drugs.\n\n"
    ,
    MYOSITIS:
      "Myositis /mixed connective tissue disease (MCTD) /systemic sclerosis overlap syndromes .\n\n" +

      "Initial symptoms: proximal muscle weakness, whole body aches, joint pain and swelling, constitutional, shortness of breath, swelling of extremities,\n" +
      "Mechanic's hands, Raynaud's phenomenon, Gottron's papules, heliotrope rash, shawl rash, V rash, .\n\n" +

      "Initial muscle power: \n\n" +

      "Initial investigation: CK Total___, CRP _, SGOT___, \n\n" +

      "Muscle biopsy: \n\n" +

      "MRI: \n\n" +

      "Myositis-Specific Antibodies (MSAs) \n\n" +

      "1. Anti-Jo-1 (Anti-histidyl-tRNA synthetase):\n" +
      "2. Anti-Mi-2:\n" +
      "3. Anti-TIF1-γ (Anti-p155/140):\n" +
      "4. Anti-NXP-2:\n" +
      "5. Anti-MDA5 (Anti-CADM-140):\n" +
      "6. Anti-SRP (Signal Recognition Particle):\n" +
      "7. Anti-HMGCR (3-Hydroxy-3-Methylglutaryl-CoA Reductase):\n\n" +


      "Myositis-Associated Antibodies (MAAs)\n" +
      "1. Anti-U1 RNP:\n" +
      "2. Anti-PM/Scl:\n" +
      "3. Anti-Ku:\n" +
      "4. Anti-Ro/SSA (Anti-SSA/Ro52): \n\n" +

      "Initial treatment: Tapering dose of prednisolone/pulse methylprednisolone, monthly cyclophosphamide _ mg, Rituximab _mg ,Methotrexate _ mg/wk ,\n" +
      "mycophenolate __mg, leflunomide_mg, \n\n" +

      "Improved/not imroved on initial therapy\n\n" +

      "Current issues: progressive weakness, worsening skin rash, new skin rashes, fever, cough.\n",
    MIXED_CONNECTIVE_TISSUE_DISEASE:
      "Mixed connective tissue disease with predominant features of systemic sclerosis/polymyositis/dermatomyositis/systemic lupus erythematosus. \n" +
      "TDI __ months/years\n\n" +

      "Initial symptoms suggestive of arthritis/arthralgia/ muscle weakness/ skin thickening/ Raynauds single,double, triple phase/ shortness of breath/\n" +
      "chest pain/ photosensitivity/constitutional symptoms.\n\n  " +

      "Pertinent Antibodies: \n\n" +

      "1. Anti-U1 RNP Antibody\n" +
      "2. Antinuclear Antibody (ANA)\n" +
      "speckled pattern.\n\n" +

      "Rheumatoid Factor (RF): \n" +
      "Anti-CCP (Cyclic Citrullinated Peptide Antibodies): \n\n" +

      "Screening for PAH: done/not done/refused/PAP\n\n" +

      "current issues: symptoms not controlled on medication. Developed new symptoms suggestive of: shortness of breath, raynauds, contitutional, arthritis,\n" +
      "fever for __ days/months",
    UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE:
      "Undifferentiated connective tissue disease/\n" +
      "TDI_months/ years\n\n" +

      "Initial symptoms suggestive of: Arthritis / arthalgia / proximal muscle weakness / photosensitivity / Oral ulcer / grittiness of eyes / Raynauds /\n" +
      "shortness of breath / pleurisy / alopacia / weight loss. \n\n" +

      "In Undifferentiated Connective Tissue Disease(UCTD), antibody testing helps identify potential autoimmune activity, monitor disease progression,\n" +
      "and rule out specific connective tissue diseases.While no single antibody is definitive for UCTD, certain markers are commonly assessed. \n\n" +

      "Pertinent Antibodies in UCTD\n\n" +

      "1. Antinuclear Antibody(ANA)\n" +
      "2. Extractable Nuclear Antigens(ENAs)\n" +
      "Anti - Ro / SSA and Anti - La / SSB:\n" +
      "Anti - U1 RNP:\n" +
      "Anti - Sm:\n" +
      "Anti - Jo - 1\n\n" +

      "3. Antiphospholipid Antibodies \n\n" +

      "Includes anti - cardiolipin, beta - 2 glycoprotein I, and lupus anticoagulant.\n\n" +



      "4. Rheumatoid Factor(RF) and Anti - Cyclic Citrullinated Peptide(Anti - CCP)\n\n" +


      "5. Myositis - Specific and Myositis - Associated Antibodies\n\n" +

      "Anti - Mi - 2, Anti - TIF1γ, Anti - MDA5: \n\n" +

      "Current issues: Symptoms suggestive of adverse effect of drugs / increased disease activity / infection of respiratory tract / soft tissue /\n" +
      "gastrointestinal tract",
    OVERLAP_SYNDROMES:
      "Overlap Syndromes/\n" +
      "TDI_months/years\n\n" +

      "Predominant symptoms of: Rheumatoid arthritis / Systemic lupus erythematosus / Systemic sclerosis / myositis.\n\n" +

      "Initial symptoms suggestive of: Arthritis / photosensitivity / Raynaud’s / constitutional symptoms / proximal muscle weakness / Renal involvement / cardiac involvement / lung involvement.\n\n" +

      "Pertinent Antibodies:\n\n" +

      "1. Anti-U1 RNP Antibody:\n" +
      "2. Anti-Jo-1:\n" +
      "3. Anti-SRP / Anti-Mi-2:\n" +
      "4. Anti-MDA5:\n" +
      "5. Anti-Scl-70 (Topoisomerase I):\n" +
      "6. Anti-Centromere:\n" +
      "7. Anti-RNA Polymerase III:\n" +
      "8. Anti-dsDNA:\n" +
      "9. Anti-Sm:\n" +
      "10. Anti-Ro/SSA / Anti-La/SSB:\n" +
      "11. Rheumatoid Factor (RF):\n" +
      "12. Anti-Cyclic Citrullinated Peptide (Anti-CCP):\n" +
      "13. Antiphospholipid Antibodies: Includes anti-cardiolipin / beta-2 glycoprotein I / lupus anticoagulant.\n\n" +
      "14. Anti-Ku:\n" +
      "15. Anti-PM/Scl:\n" +
      "16. Anti-RNP / Anti-Sm:\n\n" +

      "Current issues: Symptoms suggestive of increased disease activity / side effects of drugs / infection.",
    GOUTY_ARTHRITIS:
      "Gouty arthritis / TDI_months/years\n\n" +

      "Total numbers of attacks: _ in _ years\n\n" +

      "Total attacks in __ last 6 months\n\n" +

      "Initial attack in _ joint(s)\n\n" +

      "Current attack in ___ joint(s)\n\n" +

      "Family history: Positive / Negative - Father / brother\n\n" +

      "Current alcohol consumption: Yes / No\n\n" +

      "Comorbidities: Hypertension / Diabetes / Dislipidemia\n\n" +

      "Screening for nephrolithiasis / fatty liver / cardiac issues / dyslipidemia / Diabetes: Done / not done / refused\n\n" +

      "Current issues: Symptoms suggestive of increased disease activity / side effects of drugs.",
    VASCULITIS:
      "ANCA associated vasculitis / IgA vasculitis / Behçet’s syndrome / Takayasu arteritis\n\n" +

      "Initial symptoms suggestive of: Arthritis (symmetrical / asymmetrical) / polyarthritis / oligoarthritis involving small and large joints / upper respiratory / lower respiratory / chondritis / renal / gastrointestinal / recurrent oral / genital / cutaneous ulcer / claudication of upper / lower extremities / constitutional symptoms / proximal muscle weakness / cutaneous involvement / digital gangrene / Raynaud’s / stroke / Caratodynia for __ days/wks/months/years.\n\n" +

      "TDI = __ Days/wks/months/years\n\n" +

      "Antibodies:\n\n" +
      "1. c-ANCA/PR3: Positive / Negative\n" +
      "2. p-ANCA/MPO: Positive / Negative\n" +
      "Note: Done using indirect immunofluorescence and ELISA for confirmation.\n\n" +

      "Initial Treatment:\n\n" +
      "1. Pulse methylprednisolone _ mg for _ days / oral prednisolone 1 mg/kg\n" +
      "2. Cyclophosphamide _ mg monthly for 6 months\n" +
      "3. Rituximab _ mg per week\n" +
      "4. Methotrexate _ mg per week\n" +
      "5. Mycophenolate 500 / 1000 / 1500 mg twice daily\n" +
      "6. Azathioprine 50 / 100 mg once daily\n\n" +

      "Treatment Response: Symptoms improved / not improved / developed side effects / infection.\n\n" +

      "Conception Plan: Yes / No\n\n" +

      "Vaccination Status: Received pneumococcal vaccine / not received pneumococcal vaccine / refused pneumococcal vaccine\n\n" +

      "Smoker: Yes / No\n\n" +

      "Compliant to medication and advice: Yes / No\n\n" +

      "Current Issues: Symptoms suggestive of persistent disease activity / flare of disease / infection / side effects of drugs.",
    DEGENERATIVE_DISEASE:
      "Primary / Secondary Osteoarthritis of: Right / Left knee / First MCP / First MTP / Nodal osteoarthritis of hands / Lumbar spondylosis / Cervical Spondylosis / Right / Left Osteoarthritis\n\n" +

      "TDI: _ Months / Years\n\n" +

      "Weight: _ kg (Reduced / Increased / Static compared to previous visit)\n\n" +

      "Compliant to physiotherapy and lifestyle modification: Yes / No.",
    PRIMARY_SJÖGREN_DISEASE:
      "Primary Sjögren’s disease: Initial symptoms suggestive of exocrinopathy / arthritis / arthalgia / myalgia / constitutional symptoms / cutaneous involvement / neurological involvement / hematological involvement / renal involvement for __ wks/months/years.\n\n" +

      "TDI: __ Months / Years\n\n" +

      "Initial Treatment:\n\n" +
      "1. Methotrexate _ mg/week\n" +
      "2. Prednisolone _ mg/day\n" +
      "3. Hydroxychloroquine 200 / 300 / 400 mg daily\n" +
      "4. Mycophenolate _ mg twice daily\n\n" +

      "Treatment Response: Symptoms improved / not improved / remained static / progressed after __ months of treatment.\n\n" +

      "Compliant to medications: Compliant / Not Compliant\n\n" +

      "Current Smoker: Yes / No\n\n" +

      "Current Issues: Symptoms suggestive of persistent disease activity / infection / side effects of drugs / new symptoms.",
  };


  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();
  // const navigate = useNavigate();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const location = useLocation();
  const { full_name, problem, appointmentNumber, appointmentHistory } = location.state || {};
  const [fullNameData, setFullNameData] = useState("");
  const [problemData, setProblemData] = useState("");
  const [appointmentNumberData, setAppointmentNumberData] = useState("");

  const [hasEditableSummary, setHasEditableSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [currentSummaryId, setCurrentSummaryId] = useState(null);


  // for add summary modal
  const [selectedDiseases, setSelectedDiseases] = useState([]);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };



  const handleDiseaseSelect = (category) => {
    if (!selectedDiseases.includes(category)) {
      const newDiseases = [...selectedDiseases, category];
      setSelectedDiseases(newDiseases);

      // Update textarea with selected disease text
      const currentText = form.getFieldValue('summary') || '';
      const diseaseText = diseaseOptions[category];
      const newText = currentText ? `${currentText}\n\n${category}:\n${diseaseText}` : `${category}:\n${diseaseText}`;
      form.setFieldsValue({ summary: newText });
    }
  };


  const handleDiseaseRemove = (categoryToRemove) => {
    const newDiseases = selectedDiseases.filter(category => category !== categoryToRemove);
    setSelectedDiseases(newDiseases);

    // Update textarea by removing the category and its text
    const currentText = form.getFieldValue('summary') || '';
    const textParts = currentText.split('\n\n');
    const newText = textParts
      .filter(part => !part.startsWith(`${categoryToRemove}:`))
      .join('\n\n');
    form.setFieldsValue({ summary: newText });
  };


  const handleDiseaseSelectUpdate = (category) => {
    if (!selectedDiseases.includes(category)) {
      const newDiseases = [...selectedDiseases, category];
      setSelectedDiseases(newDiseases);

      // Update textarea with selected disease text
      const currentText = updateForm.getFieldValue('summary') || '';
      const diseaseText = diseaseOptions[category];
      const newText = currentText ? `${currentText}\n\n${category}:\n${diseaseText}` : `${category}:\n${diseaseText}`;
      updateForm.setFieldsValue({ summary: newText });
    }
  };


  const handleDiseaseRemoveUpdate = (categoryToRemove) => {
    const newDiseases = selectedDiseases.filter(category => category !== categoryToRemove);
    setSelectedDiseases(newDiseases);

    // Update textarea by removing the category and its text
    const currentText = updateForm.getFieldValue('summary') || '';
    const textParts = currentText.split('\n\n');
    const newText = textParts
      .filter(part => !part.startsWith(`${categoryToRemove}:`))
      .join('\n\n');
    updateForm.setFieldsValue({ summary: newText });
  };
  // const doctorConfig = {
  //   headers: {
  //     Authorization: "Bearer " + localStorage.getItem("doctorticket"),
  //   },
  // };

  // const staffConfig = {
  //   headers: {
  //     Authorization: "Bearer " + localStorage.getItem("staffticket"),
  //   },
  // };

  useEffect(() => {
    setFullNameData(full_name);
    setProblemData(problem);
    setAppointmentNumberData(appointmentNumber);
    fetchData();

  }, []);

  useEffect(() => {
    const checkEditableSummary = () => {
      if (!details || details.length === 0) return false;

      return details.some(item => {
        if (!item.appointmentId?.appointmentHistory) return false;

        const lastHistory = item.appointmentId.appointmentHistory[
          item.appointmentId.appointmentHistory.length - 1
        ];

        return lastHistory?.summary?.some(
          prescriptionItem => prescriptionItem._id === item._id
        );
      });
    };

    setHasEditableSummary(checkEditableSummary());
  }, [details]);

  const fetchData = () => {
    axios
      .get(`${apiBaseUrl}/summary/${appointmentId}`)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          console.log("Summary Data", response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching summary:", error);
      });
  };

  const showModal = () => {

    setIsModalOpen(true);
  };

  const showUpdateModal = (summary, summaryId) => {
    updateForm.setFieldsValue({
      summary: summary,
    });
    setCurrentSummaryId(summaryId);
    setIsUpdateModalOpen(true);
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        addSummary(values);
        form.resetFields();
        setIsModalOpen(false);
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  const handleUpdateOk = () => {
    // the value of arg is Summary ID

    updateForm
      .validateFields()
      .then((values) => {
        // console.log("Updated Summary:", { currentSummaryId, ...values });
        updateSummary(values);
        updateForm.resetFields();
        setIsUpdateModalOpen(false);
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  const handleCancel = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        title: "Are you sure you want to discard changes?",
        content: "Your changes will not be saved.",
        okText: "Yes, discard",
        cancelText: "No, keep editing",
        onOk: () => {
          form.resetFields();
          setSelectedDiseases([]);
          setIsModalOpen(false);
        },
      });
    } else {
      setIsModalOpen(false);
    }
  };

  const handleUpdateCancel = () => {
    if (updateForm.isFieldsTouched()) {
      Modal.confirm({
        title: "Are you sure you want to discard changes?",
        content: "Your changes will not be saved.",
        okText: "Yes, discard",
        cancelText: "No, keep editing",
        onOk: () => {
          updateForm.resetFields();
          setSelectedDiseases([]);
          setIsUpdateModalOpen(false);
        },
      });
    } else {
      setIsUpdateModalOpen(false);
    }
  };

  const updateSummary = async (values) => {
    setActionLoading(true);
    // console.log("Logging in update summary ", values, id);
    const data = {
      summary: values.summary,
    };
    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.put(
          `${apiBaseUrl}/doctor/summary/${currentSummaryId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.put(
          `${apiBaseUrl}/staff/summary/${currentSummaryId}`,
          data,
          config
        );
      }

      if (response.data.success) {
        message.success(response.data.message, 0.6, function onClose() {
          // window.location.reload();
          fetchData();
        });
      } else {
        message.error(
          response?.data?.error?.error || "Failed to update summary."
        );
      }
    } catch (error) {
      console.error("Failed to update summary:", error);
      message.error("Failed to update summary! Please try again.");
    } finally {
      setActionLoading(false);
    }
  }

  const addSummary = async (values) => {
    setActionLoading(true);

    const data = {
      summary: values.summary,
    };

    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.post(
          `${apiBaseUrl}/doctor/summary/${appointmentId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.post(
          `${apiBaseUrl}/staff/summary/${appointmentId}`,
          data,
          config
        );
      }

      if (response.data.success) {
        message.success(response.data.message, 0.6, function onClose() {
          // window.location.reload();
          fetchData();
        });
      } else {
        message.error(
          response?.data?.error?.error || "Failed to add summary."
        );
      }
    } catch (error) {
      console.error("Failed to add summary:", error);
      message.error("Failed to add summary! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const deleteSummary = async (summary_id) => {
    try {
      setActionLoading(true);
      if (window.confirm("Are you sure want to delete this summary?")) {
        let response;
        if (localStorage.getItem("doctorticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/doctor/summary/${summary_id}`,
            config
          );
        } else if (localStorage.getItem("staffticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/staff/summary/${summary_id}`,
            config
          );
        }
        if (response.data.success) {
          message.success(response.data.message
            // , 0.6, function onClose() {
            // window.location.reload();
            // }
          );
          fetchData();
        } else {
          message.error(
            response?.error?.message || "Failed to delete the summary."
          );
        }
      }
    } catch (e) {
      console.error("Failed to delete summary:", e);
      message.error("Failed to delete! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div className="mb-3">
        <h1 className="text-3xl font-bold decoration-gray-400">Summary</h1>
      </div>

      <div className="flex items-start">
        <div className="max-w-3xl p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow">
          {loading && <LoadingScreen />}

          {!loading && (
            <>
              <div className="text-center">
                <h5 className=" text-xl font-bold tracking-tight text-gray-900">
                  Appointment Number: {" "}
                  <span className="font-semibold italic">{` ${appointmentNumberData}`}</span>
                </h5>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                  Patient Name:{" "}
                  <span className="font-semibold italic">{` ${fullNameData}`}</span>
                </h5>

                <p className="mb-1 font-semibold text-gray-700">
                  Problem: <span className="font-normal">{` ${problemData}`}</span>
                </p>
              </div>
              <hr class="w-48 h-1 mx-auto bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700"></hr>

              {details.length > 0 ? (
                details.map((item) => {
                  return (
                    <div
                      key={item._id}
                      className="relative mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow flex"
                    >
                      {/* Left Content */}
                      <div className="flex-grow">
                        <p
                          className="mb-1 font-semibold text-gray-700"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          Summary Report:{" "}
                          <span className="font-normal">{item.summary}</span>
                        </p>
                        <p className="mb-1 font-semibold text-gray-700">
                          Added by:{" "}
                          <span className="font-normal">
                            Dr. {item.doctorId.fullname}
                          </span>
                        </p>
                      </div>

                      {/* Dotted Vertical Line */}
                      <div className="relative">
                        <div className="absolute top-0 bottom-0 border-r-2 border-dotted border-gray-400"></div>
                      </div>

                      {/* Buttons Section */}

                      {item.appointmentId.appointmentHistory[item.appointmentId.appointmentHistory.length - 1]?.summary.some(summaryItem =>
                        summaryItem._id === item._id
                      ) && (<div className="flex flex-col items-start pl-6">
                        {/* <Link */}
                        {/* // to={`/dashboard/viewOngoingAppointmentbyDoctor/update_prescription/${item._id}`}
                        // state={{ appointmentId: appointmentId }} */}
                        {/* > */}
                        <div className="ms-2">
                          <button className="px-4 py-1 mb-2 rounded-md bg-gray-600 text-sky-100 hover:bg-gray-700" onClick={() => showUpdateModal(item.summary, item._id)}>
                            Update
                          </button>
                          <Modal
                            title="Update Summary"
                            open={isUpdateModalOpen}
                            onOk={handleUpdateOk}
                            onCancel={handleUpdateCancel}
                          >
                            <Form form={updateForm} onFinish={updateSummary} layout="vertical">
                              <Row gutter={[16, 16]}>
                                <Col span={24}>
                                  <Form.Item
                                    label="Test Type"
                                    name="testType"
                                  >
                                    <Select
                                      placeholder="Select test type"
                                      onChange={handleDiseaseSelectUpdate}
                                      className="w-full"
                                    >
                                      {Object.keys(diseaseOptions).map(category => (
                                        <Select.Option key={category} value={category}>
                                          {category}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <div className="mb-4 flex flex-wrap gap-2">
                                    {selectedDiseases.map(category => (
                                      <Tag
                                        key={category}
                                        closable
                                        onClose={() => handleDiseaseRemoveUpdate(category)}
                                        className="px-2 py-1"
                                      >
                                        {category}
                                      </Tag>
                                    ))}
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    label="Summary"
                                    name="summary"
                                    rules={[
                                      { required: true, message: "Please enter summary." },
                                    ]}
                                  >
                                    <TextArea rows={13} placeholder="Enter summary..." />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </Modal>
                        </div>
                        {/* </Link> */}
                        <Link
                          onClick={() => {
                            deleteSummary(item._id);
                          }}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <div className="ms-2">
                            <button className="px-5 py-1 rounded-md bg-red-700 text-sky-100 hover:bg-red-800">
                              Delete
                            </button>
                          </div>
                        </Link>
                      </div>)}
                    </div>
                  );
                })
              ) : (
                <>
                  <p className="mt-3 text-center">
                    <h5 className="mb-1 text-xl text-red-500 font-semibold tracking-tight text-gray-900">
                      No Summary added!!
                    </h5>
                  </p>
                </>
              )}
            </>
          )}
        </div>
        {!hasEditableSummary && (<div className="ms-2">
          <Button type="primary" onClick={showModal}>
            Add Summary
          </Button>
          <Modal
            title="Add Summary"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form form={form} onFinish={addSummary} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item
                    label="Test Type"
                    name="testType"
                  >
                    <Select
                      placeholder="Select test type"
                      onChange={handleDiseaseSelect}
                      className="w-full"
                    >
                      {Object.keys(diseaseOptions).map(category => (
                        <Select.Option key={category} value={category}>
                          {category}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div className="mb-4 flex flex-wrap gap-2">
                    {selectedDiseases.map(category => (
                      <Tag
                        key={category}
                        closable
                        onClose={() => handleDiseaseRemove(category)}
                        className="px-2 py-1"
                      >
                        {category}
                      </Tag>
                    ))}
                  </div>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Summary"
                    name="summary"
                    rules={[
                      { required: true, message: "Please enter summary." },
                    ]}
                  >
                    <TextArea rows={13} placeholder="Enter summary..." />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>)}
      </div>
    </>
  );
};
export default ViewSummary;
