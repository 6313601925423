import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { message, Select, Row, Col, Typography } from "antd";
import { testTypesData } from "./report_data";


const { Option } = Select;

const AddReport = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { full_name, problem } = location.state || {};
  const [fullName_state, setFullName] = useState("");
  const [problem_state, setProblem] = useState("");
  const [followUp, setFollowUp] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(true);
  const [doctorDepartment, setDoctorDepartment] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [singleAppointmentData, setSingleAppointmentData] = useState(null);
  const [reportName, setReportName] = useState("");
  const [reportList, setReportList] = useState([
    {
      test: "",
      result: "",
      unit: "",
      refRange: "",
    },
  ]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const [unitOptions] = useState([
    'mg/dl',
    'mg/l',
    'Between 4-5%',
    'Mm/hr',
    'gm/dl',
    '/cumm',
    'millions/cumm',
    '/Hpf',
    'g/dl',
    'mmol/L',
    'µmol/L',
    'mEq/L',
    'ng/mL',
    'pg/mL',
    'U/L',
    'IU/L',
    '%',
    'K/µL',
    'cells/mcL',
    'mm/hr',
    'fL',
    'pg',
    'g/L',
    'ratio'
  ]);

  const [testTypes] = useState(testTypesData);
  const [details, setDetails] = useState([]);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        localStorage.getItem("labreporterticket"),
    },
  };

  const getResultColor = (result, refRange) => {
    if (!result || !refRange) return "bg-white";

    // Add your logic for determining if result is within range
    // This is a simple example - modify according to your needs
    const isWithinRange = true; // Add your range checking logic here
    return isWithinRange ? "bg-blue-50" : "bg-red-50";
  };

  /// -------------------- TITLE TEST
  const isTitleTest = (test) => {
    return test.test !== "" && test.result === "" && test.unit === "" && test.refRange === "";
  };

  const renderReportList = () => (
    <div className="mt-4 space-y-4">
      <table className="w-full">
        <thead>
          <tr className="border-b">
            <th className="text-left p-2">Test Name</th>
            <th className="text-left p-2">Result</th>
            <th className="text-left p-2 w-1/4">Unit</th>
            <th className="text-left p-2">Ref-Range</th>
            <th className="text-left p-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {reportList.map((medicine, index) => (
            <tr className={`border-b ${isTitleTest(medicine) ? 'bg-gray-50' : ''}`}>
              <td className="p-2">
                <input
                  className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:border-transparent transition-all duration-200"
                  type="text"
                  required
                  placeholder="eg.. LFT Test"
                  value={medicine.test}
                  onChange={(e) => handleInputChange(index, "test", e.target.value)}

                />
              </td>
              <td className="p-2">
                <input
                  className={`border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ${getResultColor(medicine.result, medicine.refRange)}`}
                  type="text"
                  placeholder="eg.. 2.76"
                  value={medicine.result}
                  onChange={(e) => handleInputChange(index, "result", e.target.value)}

                />
              </td>
              <td className="p-2">
                <Select
                  showSearch
                  className="w-full"
                  placeholder="Select unit"
                  value={medicine.unit}
                  onChange={(value) => handleInputChange(index, "unit", value)}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  popupClassName="rounded-md shadow-lg"
                >
                  {unitOptions.map((unit) => (
                    <Option key={unit} value={unit}>
                      {unit}
                    </Option>
                  ))}
                </Select>
              </td>
              <td className="p-2">
                <textarea
                  className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                  placeholder="Range"
                  value={medicine.refRange}
                  onChange={(e) => handleInputChange(index, "refRange", e.target.value)}
                  style={{
                    maxHeight: "75px",
                    minHeight: "38px",
                    resize: "none",
                    padding: "9px",
                    boxSizing: "border-box",
                    fontSize: "15px"
                  }}
                />
              </td>
              <td className="p-2">
                <IconButton
                  onClick={() => removeMedicine(index)}
                  color="danger"
                  aria-label="minus button"
                  className="hover:bg-red-100 transition-colors duration-200"
                >
                  <AiFillMinusCircle className="text-red-500 h-5 w-5" />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );



  /// In this logic, we are checking if the data (item) is in appointmenthistory or not. But since report can be added
  /// even when the status is ended, we cannot use the same logic as on prescription. Since in BE hat we are doing is,
  /// if the appointment is of status ended or referes with doctor null, then the report is added on appointment history 
  /// until we find a history with (ongoing) OR (Refered with doctor not null) OR (followUP). Then the data is added on that
  /// history. So we now need to compare the data as the same way . So we are going through a loop only if the latest appointment
  /// history is of ended or referred with doctor null until we find the required history. If not the latest is used

  const shouldShowButtons = (item) => {
    if (!item._id) return false;

    const history = singleAppointmentData?.appointmentHistory || [];
    if (history.length === 0) return false;

    let targetEntry = history[history.length - 1];

    // Only look back if latest is "Ended" or "Referred" with doctor null
    if (
      targetEntry.type === "Ended" ||
      (targetEntry.type === "Referred" && !targetEntry.doctor)
    ) {
      // Look back for the first valid entry
      for (let i = history.length - 2; i >= 0; i--) {
        const entry = history[i];
        if (
          entry.type === "Ongoing" ||
          entry.type === "FollowUp" ||
          (entry.type === "Referred" && entry.doctor)
        ) {
          targetEntry = entry;
          break;
        }
      }
    }

    // Ensure targetEntry has reports
    if (!targetEntry.reports) return false;

    // Check if the report exists in targetEntry.reports
    return targetEntry.reports.some(report => report._id === item._id);
  };




  // this is for dropdown of test for choosing test
  const handleTestTypeChange = (value) => {
    if (!value) return;

    if (!selectedTypes.includes(value)) {
      setSelectedTypes([...selectedTypes, value]);

      // Generate new report name
      const newReportName = selectedTypes.length > 0
        ? reportName + "," + testTypes[value].reportName
        : testTypes[value].reportName;
      setReportName(newReportName);

      // Replace empty default field if it's the only one
      const isDefaultEmpty = reportList.length === 1 &&
        Object.values(reportList[0]).every(v => v === "");

      const newFields = testTypes[value].fields;
      setReportList(isDefaultEmpty ? newFields : [...reportList, ...newFields]);
    }
  };
  const removeTestType = (typeToRemove) => {
    const updatedTypes = selectedTypes.filter(type => type !== typeToRemove);
    setSelectedTypes(updatedTypes);

    const newReportName = updatedTypes
      .map(type => testTypes[type].reportName)
      .join(",");
    setReportName(newReportName);

    // Calculate field counts for removed type
    const removedTypeFieldCount = testTypes[typeToRemove].fields.length;
    const currentIndex = selectedTypes.indexOf(typeToRemove);
    const startIndex = selectedTypes
      .slice(0, currentIndex)
      .reduce((acc, type) => acc + testTypes[type].fields.length, 0);

    // Remove fields for the specific type
    const updatedReportList = [
      ...reportList.slice(0, startIndex),
      ...reportList.slice(startIndex + removedTypeFieldCount)
    ];

    setReportList(updatedReportList.length ? updatedReportList : [{
      test: "",
      result: "",
      unit: "",
      refRange: ""
    }]);
  };

  const handleActionLoading = (value) => {
    setActionLoading(value);
  };

  useEffect(() => {
    setFullName(full_name);
    setProblem(problem);
    fetchData();
    fetchSingleAppointment();
    // if (localStorage.getItem("doctorticket")) {
    //   fetchDoctorDepartment();
    // }
    fetchMedicine();
  }, []);

  // const fetchMedicineName = (medId) => {
  //   setLoading(true);
  //   axios
  //     .get(`${apiBaseUrl}/medicine/${medId}`, config)
  //     .then((response) => {
  //       if (response.data.success) {
  //         setMedicineLabel(response.data.data.name);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching medicine details", error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const fetchData = () => {
    setLoading(true);

    axios
      .get(`${apiBaseUrl}/appointment-reports/${appointmentId}`, config)
      .then((response) => {
        setDetails(response.data.data);
        console.log("Data", response.data.data);
      })
      .catch((e) => {
        console.error("Failed to fetch report details:", e);
        message.error("Failed to get report details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSingleAppointment = () => {
    setButtonLoading(true);

    axios
      .get(`${apiBaseUrl}/staff/getBookedDoctorAppointment/${appointmentId}`, config)
      .then((response) => {
        setSingleAppointmentData(response.data.data);
        console.log("Single appointment data", response.data.data);

      })
      .catch((e) => {
        console.error("Failed to fetch appointment details:", e);
        message.error("Failed to get appointment details! Please try again.");
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  // const fetchDoctorDepartment = () => {
  //   setLoading(true);

  //   axios
  //     .get(`${apiBaseUrl}/doctor/profile`, doctorConfig)
  //     .then((response) => {
  //       if (response.data.success) {
  //         setDoctorDepartment(response.data.data.department.department);
  //       }
  //     })
  //     .catch((e) => {
  //       console.error("Failed to fetch report details:", e);
  //       message.error("Failed to get report details! Please try again.");
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const fetchMedicine = () => {
    axios
      .get(`${apiBaseUrl}/get/doctor/department/medicine`)
      .then((response) => {
        if (response.data.success) {
          setMedicineOptions(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching medicine:", error);
      });
  };

  const addReport = async (e) => {
    e.preventDefault();
    handleActionLoading(true);


    // Check if the followUp is empty or default date
    const isReportNameEmpty = reportName === "";

    // Check if the reportList is empty or contains only an empty default entry
    const isReportListEmpty =
      reportList.length === 0 ||
      JSON.stringify(reportList[0]) ===
      JSON.stringify({
        test: "",
        result: "",
        unit: "",
        refRange: "",
      });

    // Check if each test is valid based on the specified rule
    const isReportListValid = reportList.every((item, index) => {
      if (item.test && !item.result) {
        // Check if any subsequent test has a result
        const subsequentTestHasResult = reportList
          .slice(index + 1)
          .some(nextItem => nextItem.test && nextItem.result);
        return subsequentTestHasResult;
      }
      return true;
    });





    // Helper function to check for partially filled medicine entries
    const isMedicinePartiallyFilled = (medicine) => {
      const { test, result, unit, refRange } = medicine;
      const allFieldsEmpty = !test && !result && !unit && !refRange;
      const allFieldsFilled = test;
      return !(allFieldsEmpty || allFieldsFilled);
    };
    // Check if any medicine in the list is partially filled
    const hasPartiallyFilledMedicine = reportList.some(
      isMedicinePartiallyFilled
    );
    const data = {
      report: reportList,
      reportName: reportName,
    };
    if (isReportNameEmpty && isReportListEmpty) {
      handleActionLoading(false);

      message.error("Both medicine and followup cannot be empty");
    } else if (!isReportListValid) {
      handleActionLoading(false);
      message.error("If a test name is entered, at least one test below it must have a result filled in.");

    }

    else if (hasPartiallyFilledMedicine) {
      handleActionLoading(false);

      message.error("Medicine fields cannot be patially filled");
    } else {
      try {
        let response;
        if (localStorage.getItem("labreporterticket")) {
          response = await axios.post(
            `${apiBaseUrl}/add-report/${appointmentId}`,
            data,
            config
          );
        }
        if (response.data.success == true) {

          message.success("Report Added", 0.6, function onClose() {
            window.location.reload();
          });
        } else {
          message.error("Failed To add report");
        }
      } catch (error) {
        console.error("Failed to add report:", error);
        message.error("Failed To add report! Please try again.");
      } finally {
        handleActionLoading(false);
      }
    }
  };
  const addTest = () => {
    setReportList([
      ...reportList,
      {
        test: "",
        result: "",
        unit: "",
        refRange: "",

      },
    ]);
  };

  // Function to remove a group of tests starting from startIndex
  const removeGroup = (startIndex) => {
    let endIndex = reportList.length;
    for (let i = startIndex + 1; i < reportList.length; i++) {
      if (isTitleTest(reportList[i])) {
        endIndex = i;
        break;
      }
    }
    const updatedList = [
      ...reportList.slice(0, startIndex),
      ...reportList.slice(endIndex)
    ];
    setReportList(updatedList);
  };

  const removeMedicine = (index) => {
    if (isTitleTest(reportList[index])) {
      // Find the next title test
      let endIndex = reportList.length;
      for (let i = index + 1; i < reportList.length; i++) {
        if (isTitleTest(reportList[i])) {
          endIndex = i;
          break;
        }
      }
      // Remove from index to endIndex - 1
      const newList = [
        ...reportList.slice(0, index),
        ...reportList.slice(endIndex)
      ];
      setReportList(newList);
    } else {
      // Remove only the single test
      const newList = [...reportList];
      newList.splice(index, 1);
      setReportList(newList);
    }
  };
  const titleTestIndices = reportList.reduce((acc, test, index) => {
    if (isTitleTest(test)) {
      acc.push({ "index": index, "type": reportList[index].test });
    }
    return acc;
  }, []);

  const handleInputChange = (index, key, value) => {
    const updatedList = [...reportList];
    updatedList[index][key] = value;
    setReportList(updatedList);
  };

  const handleReportChange = (value) => {
    setReportName(value);
  }

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    // Check if the date is invalid
    if (isNaN(date.getTime())) {
      return "";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  const deleteReport = async (
    report_id,
    appointmentId,
    full_name,
    problem
  ) => {
    if (window.confirm("Are you sure want to delete this report?")) {
      handleActionLoading(true);
      try {
        let response;
        if (localStorage.getItem("labreporterticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/report/delete/${report_id}`,
            config
          );
        }

        if (response.data.success == true) {
          message.success("Report Deleted", 0.6, function onClose() {
            navigate(
              `/dashboard/add-report/${appointmentId}`,
              { state: { full_name, problem } }
            );
            window.location.reload();
          });
        } else {
          message.error("Failed to delete report.");
        }
      } catch (e) {
        console.error("Failed to delete report:", e);
        message.error("Failed to delete report! Please try again.");
      } finally {
        handleActionLoading(false);
      }
    }
  };

  return (

    <>
      {actionLoading && <LoadingScreen />}

      <div className="mb-3">
        <h1 className="text-3xl font-bold decoration-gray-400">Report</h1>
      </div>

      <div className="flex items-start">
        <div className="me-2 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow">
          {loading && <LoadingScreen />}

          {!loading && (
            <>
              <div className="max-w-3xl">
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                  Patient Name: <span className="font-semibold">{fullName_state}</span>
                </h5>
                <p className="mb-3 font-semibold text-gray-700">
                  Problem: <span className="font-normal">{problem_state}</span>
                </p>

                {details.length > 0 ? (
                  details.map((item) => (
                    <div key={item._id} className="mb-4 p-3 bg-white border border-gray-100 rounded-lg shadow">
                      <p className="mb-2 text-sm font-semibold text-gray-700">
                        Report added on: <span className="font-normal">{formatDate(item.createdAt)}</span>
                      </p>
                      <p className="mb-2 text-sm font-semibold text-gray-700">
                        Report Name: <span className="font-normal">{item.reportName}</span>
                      </p>

                      <div className="overflow-x-auto">
                        <table className="min-w-full text-sm">
                          <thead>
                            <tr className="bg-gray-50">
                              <th className="px-3 py-2 text-left font-medium text-gray-700">Test</th>
                              <th className="px-3 py-2 text-left font-medium text-gray-700">Result</th>
                              <th className="px-3 py-2 text-left font-medium text-gray-700">Unit</th>
                              <th className="px-3 py-2 text-left font-medium text-gray-700">RefRange</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.report.map((testItem, index) => (
                              <tr key={index} className="border-t border-gray-100">
                                <td className="px-3 py-2 font-medium">{testItem.test}</td>
                                <td className="px-3 py-2">{testItem.result}</td>
                                <td className="px-3 py-2">{testItem.unit}</td>
                                <td className="px-3 py-2">{testItem.refRange}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="flex justify-end mt-3 space-x-2">
                        {buttonLoading ? (
                          <LoadingScreen />
                        ) : (
                          singleAppointmentData && shouldShowButtons(item) && (
                            <>
                              <Link
                                to={`/dashboard/update-report/${item._id}`}
                                state={{ appointmentId: appointmentId, returnPage: "AddReport" }}
                              >
                                <button className="px-3 py-1 text-sm rounded-md bg-gray-600 text-sky-100 hover:bg-gray-700">
                                  Update
                                </button>
                              </Link>
                              <Link
                                onClick={() => {
                                  deleteReport(
                                    item._id,
                                    appointmentId,
                                    fullName_state,
                                    problem_state
                                  );
                                }}
                              >
                                <button className="px-3 py-1 text-sm rounded-md bg-red-700 text-sky-100 hover:bg-red-800">
                                  Delete
                                </button>
                              </Link>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="mt-3">
                    <h5 className="text-xl text-red-500 font-semibold">
                      Report not found!!
                    </h5>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {/* form card */}
        <div className="block p-6 items-center bg-white border border-gray-200 rounded-lg shadow">
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-xs font-bold mb-2"
              htmlFor="test-type"
            >
              Test Type
            </label>
            <Select
              showSearch
              className="w-full"
              placeholder="Select test type"
              onChange={handleTestTypeChange}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={null}
              allowClear
            >
              {Object.keys(testTypes).map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {selectedTypes.map((type) => (
              <div
                key={type}
                className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md"
              >
                <span className="mr-1">{type}</span>
                <button
                  type="button"
                  onClick={() => removeTestType(type)}
                  className="ml-1 text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
          <p className="text-xl mb-1 font-bold tracking-tight text-gray-800">
            Add Report
          </p>
          <div className="font-normal text-gray-700">
            <form
              className="pb-10 pt-5 mt-5 rounded-lg flex flex-col gap-4"
              onSubmit={addReport}
            >
              <div className="w-full md:w-1/4  mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-xs font-bold mb-2"
                  for="grid-zip"
                >
                  Report Name
                </label>
                <input
                  className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md placeholder-gray-300"
                  type="text"
                  required
                  placeholder="eg.. Report Name"
                  value={reportName}
                  onChange={(e) =>
                    handleReportChange(e.target.value)
                  }
                />
              </div>
              <div className="flex flex-wrap gap-2 mt-2">
                {titleTestIndices.map((type) => (
                  <div
                    key={type.type}
                    className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-md"
                  >
                    <span className="mr-1">{type.type}</span>
                    <button
                      type="button"
                      onClick={() => removeGroup(type.index)}
                      className="ml-1 text-gray-500 hover:text-gray-700"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
              {renderReportList()}
              <IconButton
                onClick={addTest}
                color="primary"
                aria-label="add button"
              >
                Add Test field
                <HiPlusCircle />
              </IconButton>


              <button
                id="addStaffBtn"
                className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddReport;
